import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import './AddInvoice.scss';
import moment from "moment";
import InvoiceInfo from "../../../../components/Invoice/InvoiceInfo";
import LineItems from "../../../../components/Invoice/LineItems";
import InvoiceTable from "../../../../components/Invoice/InvoiceTable";
import DatePicker from "react-datepicker";
import Selector from "../../../../components/elements/Selector/Selector";
import {components} from "react-select";
import {getAddress} from "../../../../utils/functions";
import {units} from "../../../../utils/constants";

export default function AddInvoice() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [data, setData] = useState(null)
    const [number, setNumber] = useState('')
    const date = new Date()
    const [items, setItems] = useState([])
    const [series, setSeries] = useState('')
    const [seriesOptions, setSeriesOptions] = useState([])
    const [total, setTotal] = useState(0)
    const [itemsOptions, setItemsOptions] = useState([])
    const [dueDate, setDueDate] = useState(new Date())
    const [unitsOptions, setUnitsOptions] = useState([])
    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    const [update, setUpdate] = useState(0)
    const [vats, setVats] = useState([])
    const [totals, setTotals] = useState([])
    const [vatsOptions, setVatsOptions] = useState([])
    const {context} = useOutletContext()

    useEffect(() => {
        getVats()
        let temp = []
        units.forEach((item) => {
            temp.push({
                value: item,
                label: intl.formatMessage({id: 'general.units.' + item})
            })
        })
        setUnitsOptions(temp)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getVats = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/vats', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setVatsOptions(response.data.data)
                getProducts(response.data.data)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProducts = async (ops) => {
        axios.post(process.env.REACT_APP_API + '/products/list/0',{search: ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.products.forEach(item => {
                    let itemToAdd = {
                        label: item.title,
                        price: item.price,
                        unit: item.unit,
                    }
                    let temp2 = ops.filter(item2 => item2.percent === item.vat)
                    if (temp2 && temp2.length > 0) {
                        itemToAdd.vat_percent = temp2[0].percent
                        itemToAdd.vat = temp2[0].value
                        itemToAdd.vat_name = temp2[0].name
                    }
                    temp.push(itemToAdd)
                })
                setItemsOptions(temp)
                getProject(ops)

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getProject = async (ops) => {
        axios.get(process.env.REACT_APP_API + '/projects/get-items-for-invoice/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setData(response.data)
                // la instaltie/bransament sa precompletam factura cu dalele din oferta
                if (response.data.items && response.data.total) {

                    let temp = []
                    response.data.items.forEach(item => {
                        let temp2 = ops.filter(item2 => item2.percent === item.vat)
                        if (temp2 && temp2.length > 0) {
                            item.vat_percent = temp2[0].percent
                            item.vat = temp2[0].value
                            item.vat_name = temp2[0].name
                        }
                        temp.push(item)

                    })

                    setItems(temp)
                    setTotal(response.data.total)
                }
                getSeries()

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.error) {
                    toast.error(response.data.error)
                } else {
                    let temp = []
                    response.data.data.forEach((item) => {
                        if (item.type === 'Factura' || item.type === 'f') temp.push({
                            value: item.name, label: item.name, number: item.next ?? item.nextNumber,
                        })
                    })
                    setSeriesOptions(temp)
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();

        if (series === '') {
            toast.error(intl.formatMessage({id: 'errors.select_invoice_series'}))
            return
        }
        if (items.length === 0) {
            toast.error(intl.formatMessage({id: 'errors.add_products'}))
            return
        }
        setSaveLoading(true)

        let payload = {
            series: series, number: number, date: date, due_date: dueDate, content: {
                client: {
                    name: data.client_type === 'company' ? data.client.company_title : data.client.first_name + ' ' + data.client.last_name,
                    cui: data.client_type === 'company' ? data.client.fiscal_code : '',
                    address: getAddress(data.client),
                    county: data.client.county,
                    city: data.client.city
                }, company: {
                    name: data.company.title,
                    email: data.company.mailjet_sender_email ?? '',
                    address_1: data.company.address,
                    city: data.company.city,
                    county: data.company.county,
                    address_2: data.company.city + ', ' + data.company.county,
                    bank_accounts: data.company.bank_accounts,
                    currency: 'ron',
                    cui: data.company.fiscal_code,
                    vat_payer: data.company.vat_payer,
                    reg_com_number: data.company.reg_com_number
                }, items: items, totals,total, vats
            },
        }
        await axios.post(process.env.REACT_APP_API + '/invoices/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate(-1)
                } else {
                    toast.error(response.data.error)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    const setVatValue = () => {
        let vats_temp = []
        let totals_temp = []
        items.forEach((item) => {
            let currentVat = vats_temp.find((vat) => vat.percent === item.vat_percent)
            let currentTotal = totals_temp.find((vat) => vat.percent === item.vat_percent)
            if (item.vat_percent !== 0) {
                if (currentVat && currentTotal) {
                    let index = -1;
                    vats_temp.forEach((vat, i) => {
                        if (vat.percent === item.vat_percent) {
                            index = i;
                        }
                    })

                    if (index > -1) {
                        vats_temp.splice(index, 1);
                    }
                    vats_temp.push({
                        percent: item.vat_percent,
                        value: parseFloat((item.qty * item.price * (item.vat_percent / 100)) + parseFloat(currentVat.value)).toFixed(2)//
                    })


                    index = -1;
                    totals_temp.forEach((tot, i) => {
                        if (tot.percent === item.vat_percent) {
                            index = i;
                        }
                    })

                    if (index > -1) {
                        totals_temp.splice(index, 1);
                    }
                    totals_temp.push({
                        percent: item.vat_percent,
                        value: parseFloat((item.qty * item.price) + parseFloat(currentTotal.value)).toFixed(2)
                    })
                } else {
                    vats_temp.push({
                        percent: item.vat_percent, value: parseFloat(item.qty * item.price * (item.vat_percent / 100)).toFixed(2)
                    })
                    totals_temp.push({
                        percent: item.vat_percent, value: parseFloat(item.qty * item.price).toFixed(2)
                    })
                }
            }
        })
        totals_temp.sort((a, b) => (a.percent > b.percent) ? 1 : -1)
        vats_temp.sort((a, b) => (a.percent > b.percent) ? 1 : -1)
        setVats(vats_temp)
        setTotals(totals_temp)
        setUpdate(update + 1)
    }
    useEffect(() => {
        setVatValue()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [total, items])
    if (loading) {
        return <LoadingComp/>
    }
    const SingleValue = props => (<components.SingleValue  {...props}>
            <span>{intl.formatMessage({id: 'general.select'})}</span>
        </components.SingleValue>);


    return (<div className="card add-invoice">
            <div className={"header"}><p>{intl.formatMessage({id: 'invoices.add_invoice'})}</p></div>
            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <InvoiceInfo
                            invoiceSeries={series}
                            series={seriesOptions}
                            event={data}
                            setInvoiceSeries={setSeries}
                            setInvoiceNumber={setNumber}
                            invoiceNumber={number}
                            invoiceDate={moment(date).format('DD/MM/YYYY')}
                            clientName={data.client_type === 'company' ? data.client.company_title : data.client.first_name + ' ' + data.client.last_name}
                            clientCui={data.client_type === 'company' ? data.client.fiscal_code : ''}
                            clientAddress={getAddress(data.client)}
                            companyCui={data.company.fiscal_code}
                            companyName={data.company.title}
                            companyEmail={data.company.mailjet_sender_email ?? ''}
                            addressStreet={data.company.address}
                            addressCityStateZip={data.company.city + ', ' + data.company.county}
                        />


                        <div className={"col-6"}>
                            <div className={"form-control"}>

                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.invoice_date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={date}
                                    dateFormat={"dd/MM/yyyy"}
                                    minDate={new Date()}
                                    disabled
                                    requires
                                />
                            </div>
                        </div>
                        <div className={"col-6"}>

                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.due_by'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    className={"form-input"}
                                    selected={dueDate}
                                    dateFormat={"dd/MM/yyyy"}
                                    minDate={new Date()}
                                    onChange={(date) => {
                                        context.setContentUpdated(true)
                                        setDueDate(date)
                                    }}
                                    requires
                                />
                            </div>
                        </div>
                        <div className={"col-12 mt-4"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.offer.add_product_in_offer'})}</div>
                                <Selector
                                    options={itemsOptions}
                                    value={0}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        option.title = option.label
                                        option.qty = 1
                                        setItems(items.concat([option]))
                                        setTotal(parseFloat(total + option.qty * option.price))
                                    }}
                                    isSearchable={true}
                                    components={{SingleValue}}
                                />
                            </div>
                        </div>

                        <LineItems
                            items={items}
                            setItems={setItems}
                            setTotal={setTotal}
                            total={total}
                            vatsOptions={vatsOptions}
                            unitsOptions={unitsOptions}
                        />

                        <InvoiceTable
                            hasBottomBorder={true}
                            headings={(<>
                                    <th>{intl.formatMessage({id: 'invoices.payment_info'})}</th>
                                    <th className={"right-aligned-cell"}>{intl.formatMessage({id: 'invoices.due_by'})}</th>
                                    <th>{intl.formatMessage({id: 'invoices.total'})}</th>
                                </>)}
                        >
                            <tr>
                                <td className={"payment-info"}>
                                    <div>
                                        {intl.formatMessage({id: 'invoices.account_no'})} : <strong>
                                        {data.company.bank_account}</strong>
                                    </div>
                                </td>
                                <td className={"right-aligned-cell"}>
                                    <span>{moment(dueDate).format('DD/MM/YYYY')}</span></td>
                                <td className={"total right-aligned-cell"} key={update}>
                                    <span className={"large"}>
                                        <strong>{parseFloat(total).toLocaleString('ro-RO')} RON</strong>
                                    </span>


                                    {vats.map((vat) => (<div className={"vat-container"} key={vat.percent}>
                                            <div
                                                className={"pe-3"}>{intl.formatMessage({id: 'general.vat'})}({vat.percent}%)
                                            </div>
                                            <div>{parseFloat(vat.value).toLocaleString('ro-RO')}</div>
                                        </div>))}
                                </td>
                            </tr>
                        </InvoiceTable>

                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 d-flex"}>
                            <Button
                                className={"btn-secondary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                type={'submit'}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                        </div>
                        {window.alityAppView && <div className={"col-12"}>
                            <Button
                                className={"btn-light mt-2"}
                                icon={"fa-solid fa-chevron-left"}
                                value={intl.formatMessage({id: 'general.buttons.back'})}
                                onClick={() => {

                                    context.setLeavePage(true)
                                    context.setNextLink(-1)
                                }}
                            />
                        </div>}
                    </div>
                </div>
            </form>
        </div>);
}

