import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Series() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [series, setSeries] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/document-series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setSeries(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    const deleteItem = async (id) => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/document-series/' + itemToDelete, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                } else {
                    toast.error(intl.formatMessage({id: 'settings.documents_series.error_delete_document_series'}))
                }
                setLoadingDelete(false)
                setModalIsOpen(false)
            })
            .catch(err => {
                errorParser(err, intl)
                setLoadingDelete(false)
            })
    }
    return (
        <div className="card">

            <div className={"header align-items-center"}>

                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'settings.menu.documents_series'})}</p>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        <Button
                            className={"d-block btn-primary ms-auto"}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/company/documents-series/add')}
                        />
                    </div>
                </div>
            </div>


            {
                series.length === 0 ?
                    <div className={"content"}>
                        <div className={"alert alert-warning"}>
                            {intl.formatMessage({id: 'settings.series.alert_no_series'})}
                        </div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.series.series'})}</div>
                                    <div
                                        className={"col-2 table-td"}>{intl.formatMessage({id: 'settings.series.next_number'})}</div>
                                    <div
                                        className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.series.type'})}</div>
                                    <div className={"col-4 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    series.map((serie, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div className={"col-3 table-td"}>{serie.series}</div>
                                                <div className={"col-2 table-td"}>{serie.next_number}</div>
                                                <div className={"col-3 table-td"}>{intl.formatMessage({id: 'settings.series.type.' + serie.for})}</div>
                                                <div className={"col-4 table-td"}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/company/documents-series/' + serie.id)}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash-alt"}
                                                        tooltip={intl.formatMessage({id: 'general.delete'})}
                                                        onClick={() => {
                                                            setItemToDelete(serie.id)
                                                            setModalIsOpen(true)
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }

            <div className={"footer"}>

            </div>

            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={() => deleteItem()}
            />
        </div>

    );
}

