import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import '../Requests.scss'
import UploadDocumentsForm from "../../Owner/ProjectRequests/EditRequest/UploadDocumentsForm";
import {useParams} from "react-router-dom";
import SignOffer from "./SignOffer";
import SignContract from "./SignContract";

export default function ClientRequest() {

    const intl = useIntl();
    let params = useParams();


    const [request, setRequest] = useState(null)

    const [loading, setLoading] = useState(true)
    const [updateRequest, setUpdateRequest] = useState(0)

    useEffect(() => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('role')
        localStorage.removeItem('access')
        localStorage.removeItem('subscription_expiration_date')

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRequest])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/find-by-code/' + params.code, {
            headers: {
            }
        })
            .then(async (response) => {
                setRequest(response.data)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="request container">

            <div className={"row"}>
                <div className={"col-md-8 col-sm-12"}>
                    <h1 className={"container-title"}> {intl.formatMessage({id: 'requests.type.' + request.type})}
                    </h1>
                </div>
                <div className={"col-md-4 col-sm-12"}>
                    <p className={"status-container"}>
                        {intl.formatMessage({id: 'general.status'})}
                        : {intl.formatMessage({id: 'requests.status.' + request.status})}
                    </p>
                </div>
                <div className={"col-md-12"}>
                    {(() => {    let content = ''
                        // eslint-disable-next-line
                        switch (request.status) {
                            case 'new_waiting_documents':
                            case 'partially_loaded_documents':
                                content = <div className="card">
                                    <div className={"header"}>

                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <p>{intl.formatMessage({id: 'requests.upload_documents'})}</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"card-content"}>
                                        <UploadDocumentsForm
                                            getDataLink={'/project-requests/find-by-code/' + params.code}
                                            saveLink={'/project-requests/upload-documents-by-code/' + params.code}
                                            updateRequest={updateRequest}
                                            setUpdateRequest={setUpdateRequest}
                                        />
                                    </div>

                                </div>
                                break;
                            case 'uploaded_documents':
                                content =
                                    <div className={"alert alert-info"}>
                                        {intl.formatMessage({id: 'requests.info.uploaded_documents'})}
                                    </div>
                                break;
                            case 'offer_generated':
                            case 'verified_documents':
                                content =
                                    <div className={"alert alert-info"}>
                                        {intl.formatMessage({id: 'requests.info.verified_documents'})}
                                    </div>
                                break;
                            case 'completed':
                                content =
                                    <div className={"alert alert-info"}>
                                        {intl.formatMessage({id: 'requests.info.verified_documents'})}
                                    </div>
                                break;
                            case 'offer_sent':
                                content =   <SignOffer code={params.code}
                                                       updateRequest={updateRequest}
                                                       setUpdateRequest={setUpdateRequest}/>
                                break;
                            case 'contract_generated':
                            case 'offer_signed':
                                content =
                                    <div className={"alert alert-info"}>
                                        {intl.formatMessage({id: 'requests.info.offer_signed'})}
                                    </div>
                                break;
                            case 'contract_sent':
                                content =   <SignContract code={params.code}
                                                       updateRequest={updateRequest}
                                                       setUpdateRequest={setUpdateRequest}/>
                                break;
                            case 'contract_signed':
                                content =
                                    <div className={"alert alert-info"}>
                                        {intl.formatMessage({id: 'requests.info.contract_signed'})}
                                    </div>
                                break;

                        }

                        return content
                    })()}
                </div>
            </div>
        </div>
    );
}

