import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import '../Requests.scss'
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Button from "../../../../components/elements/Button/Button";
import axios from "axios";
import {documentsToUpload} from "../../../../utils/constants";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {toast} from "react-toastify";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import FileContainerWithVerification from "./FileContainerWithVerification";

export default function DocumentsVerification() {
    const intl = useIntl();
    const navigate = useNavigate();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()

    const [documentsToUploadForCurrentType, setDocumentsToUploadForCurrentType] = useState([])
    const [dataToUpload, setDataToUpload] = useState(null)
    const [verifiedDocuments, setVerifiedDocuments] = useState(null)
    const [request, setRequest] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        axios.get(process.env.REACT_APP_API + '/project-requests/' + params.id, {
            headers
        })
            .then(async (response) => {
                setRequest(response.data)
                setVerifiedDocuments(response.data.verified_documents ?? {})
                setDocumentsToUploadForCurrentType(documentsToUpload[response.data.type])
                let temp = {}
                temp.consumption_place_code = response.data.consumption_place_code
                temp.gas_installation_file_number = response.data.gas_installation_file_number
                temp.seal_number = response.data.seal_number
                temp.counter_series = response.data.counter_series
                temp.counter_index = response.data.counter_index
                temp.has_attr = response.data.has_attr
                temp.attr_number = response.data.attr_number
                temp.gas_supplier_invoice_or_consumption_place_code = response.data.gas_supplier_invoice_or_consumption_place_code

                setDataToUpload(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.put(process.env.REACT_APP_API + '/project-requests/verify-documents/' + params.id, {verified_documents: verifiedDocuments}, {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (context.setUpdateStatus) {
                        context.setUpdateStatus(context.updateStatus + 1)
                    }
                    if (context)
                        context.setContentUpdated(false)
                    getData()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const complete = async () => {
        setSaveLoading(true)

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.get(process.env.REACT_APP_API + '/project-requests/complete/' + params.id,  {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate('/projects')
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const deleteDocument = async (document_key, document_id) => {
        setSaveLoading(true)

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.put(process.env.REACT_APP_API + '/project-requests/delete-document/' + params.id, {
            document_key,
            document_id
        }, {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    const sendEmailToReUploadDocuments = async () => {
        setSaveLoading(true)

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.get(process.env.REACT_APP_API + '/project-requests/send-email-to-reupload-documents/' + params.id, {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'general.email_has_been_sent'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading)
        <LoadingComp/>

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12"}>
                        <p>{intl.formatMessage({id: 'requests.documents_verification'})}</p>
                    </div>
                    <div className={"col-md-6 col-sm-12"}>
                        {
                            request && request.status === 'verified_documents' ?
                                (request.type === 'check' || request.type === 'revision') && <Button
                                    className={"btn-primary d-flex align-items-center ms-auto"}
                                    icon={"fa-solid fa-check"}
                                    value={intl.formatMessage({id: 'requests.complete'})}
                                    onClick={() => complete()}
                                />
                                :
                                request && request.client.email && <Button
                                    className={"btn-primary d-flex align-items-center ms-auto"}
                                    icon={"fa-solid fa-envelope-open-text"}
                                    value={intl.formatMessage({id: 'general.send_email_to_reupload_documents'})}
                                    onClick={() => sendEmailToReUploadDocuments()}
                                />
                        }
                    </div>
                </div>
            </div>


            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        {
                            // eslint-disable-next-line
                            documentsToUploadForCurrentType.map((item, i) => {
                                // eslint-disable-next-line
                                switch (item.type) {
                                    case 'file':
                                        if ((item.if_client && request.client.type === item.if_client) || !item.if_client) {
                                            return (
                                                <FileContainerWithVerification request={request}
                                                                               fileKey={item.key}
                                                                               multiple={item.multiple}
                                                                               verified={verifiedDocuments[item.key]}
                                                                               toggleVerified={() => {
                                                                                   context.setContentUpdated(true)
                                                                                   let temp = {...verifiedDocuments}
                                                                                   temp[item.key] = !temp[item.key]
                                                                                   setVerifiedDocuments(temp)
                                                                               }}
                                                                               deleteDocument={(key, id) => deleteDocument(key, id)}
                                                />)
                                        }
                                        break;
                                    case 'text':
                                        return (<div className={"col-12"}>
                                            <div className={"form-control"}>
                                                <div
                                                    className={"form-label"}>{intl.formatMessage({id: 'requests.' + item.key})}
                                                    {item.required && <span
                                                        className={"form-required"}>*</span>}</div>
                                                <input type={"text"} className={"form-input"}

                                                       value={dataToUpload[item.key]} required
                                                       onChange={(e) => {
                                                           context.setContentUpdated(true)
                                                           let temp = {...dataToUpload}
                                                           temp[item.key] = e.target.value
                                                           setDataToUpload(temp)
                                                       }}
                                                />
                                            </div>
                                        </div>)
                                    case 'switch':
                                        return (<div className={"col-12 mt-3"}>
                                            <div className={"tab-switch with-content"}>
                                                {
                                                    item.values.map((item2, j) => (
                                                        <div
                                                            className={"tab " + (dataToUpload[item.key] === item2.key ? "active" : "")}
                                                            onClick={() => {
                                                                context.setContentUpdated(true)
                                                                let temp = {...dataToUpload}
                                                                temp[item.key] = item2.key
                                                                setDataToUpload(temp)
                                                            }}>
                                                            {intl.formatMessage({id: 'requests.' + item2.key})}
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                            <div className={"tab-content p-2"}>

                                                {
                                                    //eslint-disable-next-line
                                                    item.values.map((item2, j) => {
                                                        if (dataToUpload[item.key] === item2.key)
                                                            //eslint-disable-next-line
                                                            switch (item2.type) {
                                                                case 'file':
                                                                    return (
                                                                        <FileContainerWithVerification
                                                                            request={request}
                                                                            fileKey={item2.key}
                                                                            multiple={item2.multiple}
                                                                            verified={verifiedDocuments[item2.key]}
                                                                            toggleVerified={() => {
                                                                                context.setContentUpdated(true)
                                                                                let temp = {...verifiedDocuments}
                                                                                temp[item2.key] = !temp[item2.key]
                                                                                setVerifiedDocuments(temp)
                                                                            }}
                                                                            deleteDocument={(key, id) => deleteDocument(key, id)}
                                                                        />)
                                                                case 'text':
                                                                    return (
                                                                        <div className={"col-12"}>
                                                                            <div
                                                                                className={"form-control"}>
                                                                                <div
                                                                                    className={"form-label"}>{intl.formatMessage({id: 'requests.' + item2.key})}
                                                                                    {item2.required &&
                                                                                        <span
                                                                                            className={"form-required"}>*</span>}</div>
                                                                                <input type={"text"}
                                                                                       className={"form-input"}

                                                                                       value={dataToUpload[item2.key]}
                                                                                       required
                                                                                       onChange={(e) => {
                                                                                           context.setContentUpdated(true)
                                                                                           let temp = {...dataToUpload}
                                                                                           temp[item2.key] = e.target.value
                                                                                           setDataToUpload(temp)
                                                                                       }}
                                                                                />
                                                                            </div>
                                                                        </div>)
                                                            }
                                                    })}
                                            </div>
                                        </div>)
                                }
                            })
                        }
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

