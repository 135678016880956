import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Requests.scss'
import Button from "../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment/moment";
import Selector from "../../../../../components/elements/Selector/Selector";
import ModalAddProductInOffer from "../ModalAddProductInOffer";

export default function EditOffer() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()
    const columnTypeOptions = [{
        value: 'common',
        label: intl.formatMessage({id: 'request.column_type.common'})
    },{
        value: 'personal',
        label: intl.formatMessage({id: 'request.column_type.personal'})
    }]

    const [offerId, setOfferId] = useState(null)
    const [status, setStatus] = useState('')
    const [templates, setTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState(null)
    const [number, setNumber] = useState(null)
    const [seriesOptions, setSeriesOptions] = useState([])
    const [series, setSeries] = useState(null)
    const [products, setProducts] = useState('')
    const [showModalAddProduct, setShowModalAddProduct] = useState(false)
    const [index, setIndex] = useState(null)
    const [columnType, setColumnType] = useState('')
    const [total, setTotal] = useState(0)


    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    useEffect(() => {

        getTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOffer = async (temp) => {
        axios.get(process.env.REACT_APP_API + '/offers/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.offer && response.data.offer.id) {
                    setOfferId(response.data.offer.id)
                    setTemplateSelected(response.data.offer.document_template.id)
                    setNumber(response.data.offer.number)
                    setSeries(temp.find(item => item.label === response.data.offer.series).value)
                    setColumnType(response.data.column_type)
                    setProducts(response.data.offer.products)
                    setTotal(response.data.offer.total)
                }
                setStatus(response.data.status)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTemplates = async () => {
        axios.post(process.env.REACT_APP_API + '/document-templates/find-for-request/' + params.id, {type: 'offer'}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.templates) {
                    let temp = []
                    response.data.templates.forEach(item => {
                        temp.push({
                            value: item.id,
                            label: item.title,
                            content: item.content,
                            header: item.header
                        })
                    })
                    setTemplates(temp)
                }
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/document-series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data && response.data.length > 0) {
                    let temp = []
                    response.data.forEach(item => {
                        if (item['for'] === 'offer')
                            temp.push({
                                value: item.id,
                                label: item.series,
                                next_number: item.next_number,
                            })
                    })
                    setSeriesOptions(temp)
                    await getOffer(temp)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (sent_to_client = false) => {
        if (products.length === 0 || series === null || number === null || templateSelected === null || columnType === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)


        let payload = {
            date: moment().format('YYYY-MM-DD'),
            series: seriesOptions.find(item => item.value === series).label,
            number,
            template: templateSelected,
            products,
            column_type: columnType,
            sent_to_client,
            total,
            offer_id: offerId

        }

        await axios.put(process.env.REACT_APP_API + '/offers/save-for-request/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getOffer(seriesOptions)
                }

                context.setContentUpdated(false)
                if (context.setUpdateStatus){
                    context.setUpdateStatus(context.updateStatus + 1)
                }
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    const updateTotal = async (prods) => {
        let temp = 0

        prods.forEach(prod => {
            temp += parseFloat(parseFloat(prod.price * prod.qty).toFixed(2))
        })
        setTotal(temp)
        context.setContentUpdated(true)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12 "}>
                        <p>{intl.formatMessage({id: 'requests.offer'})}
                        </p>
                    </div>
                </div>
            </div>

                <div className={"content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={seriesOptions}
                                    value={series}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setNumber(option.next_number)
                                        setSeries(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} className={"form-input"} required
                                       value={number} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.template'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={templates}
                                    value={templateSelected}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setTemplateSelected(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'request.column_type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={columnTypeOptions}
                                    value={columnType}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setColumnType(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.sidebar.products'})}<span
                                className={"form-required"}>*</span></div>


                            {products && products.length >0 && <div className={"table mb-1"}>

                                <div className={"table-head"}>
                                    <div className={"row"}>
                                        <div
                                            className={"col-8 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.qty'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{intl.formatMessage({id: 'general.price'})}</div>
                                    </div>
                                </div>

                                <div className={"table-body"}>
                                    {
                                        products.map((product, i) => (
                                            <div className={"table-row"} key={'product'+i} style={{cursor: 'pointer'}}
                                            onClick={() => {
                                                setIndex(i)
                                                setShowModalAddProduct(true)
                                            }}>
                                                <div className={"row"}>
                                                    <div
                                                        className={"col-8 table-td"}>

                                                      <div>
                                                          <strong style={{display: "block"}}>{product.title}</strong>
                                                          {
                                                              product.items && product.items.length > 0 && <div>
                                                                  {intl.formatMessage({id: "general.products.included_items"})}:
                                                                  {
                                                                      product.items.map((item,j) => (
                                                                          <p key={'item' +i+ j}>{j+1}. {item}</p>
                                                                      ))
                                                                  }
                                                              </div>
                                                          }
                                                      </div>
                                                    </div>
                                                    <div
                                                        className={"col-2 table-td"}>{product.qty}
                                                    </div>
                                                    <div className={"col-2 table-td"}>
                                                        {
                                                            product.price
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className={"row"}>
                                        <div
                                            className={"col-10 table-td"}>{intl.formatMessage({id: 'general.total'})}</div>
                                        <div
                                            className={"col-2 table-td"}>{total}</div>
                                    </div>
                                </div>
                            </div>
                            }
                            <Button
                                className={"btn-secondary mt-2 w-100"}
                                icon={"fa-solid fa-plus"}
                                style={{width: '100%'}}
                                value={intl.formatMessage({id: 'general.offer.add_product_in_offer'})}
                                onClick={() => {
                                    setIndex(null)
                                    setShowModalAddProduct(true)
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {
                                        context.setLeavePage(true)
                                        context.setNextLink('/requests/'+params.id+'/offer')
                                    }}
                                />
                            </div>
                        <div className={"col-12 col-md-8 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'general.buttons.' + (offerId ? 'save' : 'generate')})}
                                type={'button'}
                                onClick={() => save(false)}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                            {status !== 'offer_sent' && <Button
                                className={"btn-primary mt-2 ms-3"}
                                icon={"fa-solid fa-share"}
                                value={intl.formatMessage({id: 'general.buttons.generate_and_send'})}
                                type={'button'}
                                onClick={() => save(true)}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />}
                        </div>

                    </div>
                </div>
            <ModalAddProductInOffer modalIsOpen={showModalAddProduct}
                                    setModalIsOpen={setShowModalAddProduct}
                                    updateTotal={updateTotal} products={products}
                                    setProducts={(value) => {
                                        context.setContentUpdated(true)
                                        setProducts(value)
                                    }} index={index}
            />
        </div>
    );
}