import {useIntl} from "react-intl";
import React from "react";
import '../Requests.scss'

export default function FileContainerWithVerification({
                                                          request,
                                                          fileKey,
                                                          verified,
                                                          toggleVerified,
                                                          deleteDocument,
                                                          multiple = false
                                                      }) {

    const intl = useIntl();

    return (
        <div className={"col-12"}>
            <div
                className={"form-label mt-3"}>{intl.formatMessage({id: 'requests.' + fileKey})}<span
                className={"form-required"}>*</span>
            </div>
            {
                request && request[fileKey] ?
                    request[fileKey].length > 0 ?
                        request[fileKey].map(file => (
                            <div className={"row mb-3"}>
                                <div className={(verified ? 'col-md-6' : 'col-md-4') + " col-sm-12"}>
                                    <a href={process.env.REACT_APP_API.replace('/api', '') + file.url}
                                       target={"_blank"} rel={"noreferrer"}>
                                        <div
                                            className={"alert alert-success"}>{intl.formatMessage({id: 'general.buttons.download'})}</div>
                                    </a>


                                </div>
                                <div className={(verified ? 'col-md-6' : 'col-md-4') + " col-sm-12"}
                                     style={{cursor: "pointer"}}>
                                    <div className={"alert alert-" + (verified ? 'warning' : 'info')}
                                         onClick={toggleVerified}
                                    >{intl.formatMessage({id: 'requests.' + (verified ? 'mark_as_unverified' : 'mark_as_verified')})}</div>
                                </div>
                                {!verified &&
                                    <div className={"col-md-4 col-sm-12"} style={{cursor: "pointer"}}>
                                        <div
                                            className={"alert alert-danger"}
                                            onClick={() => deleteDocument(fileKey, file.id)}>({intl.formatMessage({id: 'general.buttons.delete'})})
                                        </div>
                                    </div>}
                            </div>
                            ))
                        :
                        <div className={"row mb-3"}>
                            <div className={(verified ? 'col-md-6' : 'col-md-4') + " col-sm-12"}>
                                {
                                    multiple ?
                                        <div className={"alert alert-success"}
                                             style={{cursor: "pointer"}}
                                             onClick={() => {
                                                 request[fileKey].forEach(item => {
                                                     var link = document.createElement("a");
                                                     link.download = item.url.split('/')[2];
                                                     link.target = "_blank"
                                                     link.href = process.env.REACT_APP_API.replace('/api', '') + item.url;
                                                     document.body.appendChild(link);
                                                     link.click();
                                                     document.body.removeChild(link);
                                                 })
                                             }}
                                        >{intl.formatMessage({id: 'requests.document_uploaded'})} ({intl.formatMessage({id: 'general.buttons.download'})})</div>
                                        :
                                        <a href={process.env.REACT_APP_API.replace('/api', '') + request[fileKey].url}
                                           target={"_blank"} rel={"noreferrer"}>
                                            <div
                                                className={"alert alert-success"}>{intl.formatMessage({id: 'general.buttons.download'})}</div>
                                        </a>
                                }

                            </div>
                            <div className={(verified ? 'col-md-6' : 'col-md-4') + " col-sm-12"}
                                 style={{cursor: "pointer"}}>
                                <div className={"alert alert-" + (verified ? 'warning' : 'info')}
                                     onClick={toggleVerified}
                                >{intl.formatMessage({id: 'requests.' + (verified ? 'mark_as_unverified' : 'mark_as_verified')})}</div>
                            </div>
                            {!verified &&
                                <div className={"col-md-4 col-sm-12"} style={{cursor: "pointer"}}>
                                    <div
                                        className={"alert alert-danger"}
                                        onClick={() => deleteDocument(fileKey, request[fileKey].id)}>({intl.formatMessage({id: 'general.buttons.delete'})})
                                    </div>
                                </div>}
                        </div>
                    :

                    <div className={"row mb-3"}>
                        <div className={"col-sm-12"}>
                            <div
                                className={"alert alert-danger"}>{intl.formatMessage({id: 'requests.deleted_not_uploaded_document'})}</div>
                        </div>
                    </div>
            }
        </div>
    );
}

