import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useParams} from "react-router-dom";
import '../../../Contracts/Contracts.scss';
import Button from "../../../../../components/elements/Button/Button";
import moment from "moment";
import {toast} from "react-toastify";
import ModalConfirmDelete from "../../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Annexes() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loading, setLoading] = useState(true)
    const [annexes, setAnnexes] = useState([])
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(0)

    useEffect(() => {
        getAnnexes()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getAnnexes = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/' + params.contract, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setAnnexes(response.data.annexes)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteItem = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/annexes/' + itemToDelete, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getAnnexes()
                } else {
                    toast.error(intl.formatMessage({id: 'contracts.error_delete_annex'}))
                }
                setLoadingDelete(false)
                setModalIsOpen(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const download = async (annex) => {
        let load = [];
        load[annex.id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/annexes/download/' + annex.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/annexes/download/' + annex.id, {
                responseType: 'blob',
                headers: {
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'annex-' +annex.contract.series+annex.contract.number+"-"+ annex.number + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                })
        }
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-xl-6 col-sm-12 "}>
                        <p> {intl.formatMessage({id: 'contracts.annexes'})}</p>
                    </div>
                    <div className={"col-xl-6 col-sm-12 d-flex"}>
                        <Button
                            className={"btn-primary ms-auto "}
                            icon={"fa-solid fa-plus"}
                            value={intl.formatMessage({id: 'general.buttons.add'})}
                            onClick={() => navigate('/requests/'+params.id+'/contract/'+params.contract+'/annexes/add')}
                        />
                    </div>
                </div>

            </div>


            {
                annexes.length === 0 ?
                    <div className={"content"}>

                        <div className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_annexes_found'})}</div>
                    </div>
                    :
                    <div className={"content p-0"}>
                        <div className={"table mb-1"}>

                            <div className={"table-head"}>
                                <div className={"row"}>
                                    <div
                                        className={"col-2 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                                    <div
                                        className={"col-4 table-td"}>{intl.formatMessage({id: 'general.date'})}</div>
                                    <div className={"col-6 table-td"}></div>
                                </div>
                            </div>


                            <div className={"table-body"}>
                                {
                                    annexes.map((annex, i) => (
                                        <div className={"table-row"} key={i}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-2 table-td"}>
                                                    {annex.number}
                                                </div>
                                                <div
                                                    className={"col-4 table-td"}>
                                                    {moment(annex.date).format('Do MMMM YYYY')}
                                                </div>
                                                <div className={"col-6 table-td "}>
                                                    <Button
                                                        className={"btn-light btn-edit ms-auto"}
                                                        icon={"fa-solid fa-download"}
                                                        whiteLoader={false}
                                                        loading={loadingDownload[annex.id] ?? false}
                                                        disabled={loadingDownload[annex.id] ?? false}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                        onClick={() => {
                                                            download(annex)
                                                        }}
                                                    />
                                                    <Button
                                                        className={"btn-light btn-edit"}
                                                        icon={"fa-solid fa-pen"}
                                                        tooltip={intl.formatMessage({id: 'general.edit'})}
                                                        onClick={() => navigate('/requests/'+params.id+'/contract/'+params.contract+'/annexes/' + annex.id)}
                                                    />
                                                   <Button
                                                        className={"btn-light btn-delete"}
                                                        icon={"fa-solid fa-trash-alt"}
                                                        tooltip={intl.formatMessage({id: 'general.buttons.delete'})}
                                                        whiteLoader={false}
                                                        onClick={() => {

                                                            setItemToDelete(annex.id)
                                                            setModalIsOpen(true)
                                                        }}
                                                    />
                                                   <div className={"d-grid"}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
            }
            <div className={"footer d-flex justify-content-center"}>
            </div>
            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'general.delete'})}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={() => deleteItem()}
            />
        </div>
    );
}

