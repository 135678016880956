import {useIntl} from "react-intl";
import React from "react";
import AddProductForm from "./AddProductForm";

export default function AddProduct() {

    const intl = useIntl();
    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.products'})}</h1>
                </div>

                <div className={"col-md-12"}>
                    <div className="card">

                        <div className={"header"}>
                            <p>{intl.formatMessage({id: 'general.add_product'})}</p></div>

                        <AddProductForm id={0} showBackButton={true}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

