import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import '../Requests.scss'
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import DatePicker from "react-datepicker";
import moment from "moment/moment";

export default function EditRequest() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()

    const [client, setClient] = useState(null)
    const [placeOfConsumption, setPlaceOfConsumption] = useState(null)
    const [date, setDate] = useState('')

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setClient(response.data.client)
                setPlaceOfConsumption(response.data.place_of_consumption)
                setDate(new Date(response.data.project_date))
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let payload = {
            project_date: moment(date).toISOString(),
        }

        await axios.put(process.env.REACT_APP_API + '/project-requests/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    context.setContentUpdated(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'requests.edit_request'})}</p>
                    </div>
                </div>
            </div>

            <form onSubmit={(e) => save(e)}>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.client'})}: {client.type === 'individual' ?
                                    (client.first_name + ' ' + client.last_name) : client.company_title}
                                </div>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.place_of_consumption'})}: {placeOfConsumption.address
                                    +', '+ placeOfConsumption.city +', '+placeOfConsumption.county} </div>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.project_date'})}<span
                                    className={"form-required"}>*</span></div>
                                <DatePicker
                                    locale={moment.locale()}
                                    placeholderText={intl.formatMessage({id: 'general.project_date'})}
                                    className={"form-input"}
                                    selected={date}
                                    onChange={(date) => {
                                        setDate(date)
                                        context.setContentUpdated(true)
                                    }}
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6"}>
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                icon={"fa-regular fa-floppy-disk"}
                                value={intl.formatMessage({id: 'general.buttons.save'})}
                                disabled={saveLoading}
                                loading={saveLoading}
                                type={"submit"}
                            />
                        </div>
                    </div>
                </div>
            </form>

        </div>
    );
}

