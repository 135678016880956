import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import Button from "../../elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment";
import ModalComp from "../../../components/ModalComp/ModalComp";
import Selector from "../../elements/Selector/Selector";

export default function InvoicesTable({
                                          invoices,
                                          getData,
                                          selectedInvoices,
                                          setSelectedInvoices,
                                          project = null
                                      }) {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const optionsOblio = [{
        value: 'Chitanta',
        label: 'Chitanta'
    }, {
        value: 'Bon fiscal',
        label: 'Bon fiscal'
    }, {
        value: 'Alta incasare numerar',
        label: 'Alta incasare numerar'
    }, {
        value: 'Ordin de plata',
        label: 'Ordin de plata'
    }, {
        value: 'Mandat postal',
        label: 'Mandat postal'
    }, {
        value: 'Card',
        label: 'Card'
    }, {
        value: 'CEC',
        label: 'CEC'
    }, {
        value: 'Bilet ordin',
        label: 'Bilet ordin'
    }, {
        value: 'Alta incasare banca',
        label: 'Alta incasare banca'
    }]
    const optionsSmartbill = [{
        value: 'Chitanta',
        label: 'Chitanta'
    }, {
        value: 'Ordin plata',
        label: 'Ordin plata'
    }, {
        value: 'Mandat postal',
        label: 'Mandat postal'
    }, {
        value: 'Card',
        label: 'Card'
    }, {
        value: 'CEC',
        label: 'CEC'
    }, {
        value: 'Card online',
        label: 'Card online'
    }, {
        value: 'Extras de cont',
        label: 'Extras de cont'
    }, {
        value: 'Ramburs',
        label: 'Ramburs'
    }, {
        value: 'Alta incasare',
        label: 'Alta incasare'
    }]

    const [loadingDownload, setLoadingDownload] = useState([])
    const [loadingDelete, setLoadingDelete] = useState([])
    const [loadingCancel, setLoadingCancel] = useState([])
    const [user, setUser] = useState(null)
    const [modalIsOpenCollect, setModalIsOpenCollect] = useState(false)
    const [invoiceSelected, setInvoiceSelected] = useState('')
    const [loadingCollect, setLoadingCollect] = useState(false)
    const [collect, setCollect] = useState('')
    const [docNr, setDocNr] = useState(0);
    const [options, setOptions] = useState([]);
    const [seriesChit, setSeriesChit] = useState('');

    const download = async (invoice, project) => {
        let load = [];
        load[invoice.id] = true;

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + project.id,
            }))

        } else {
            setLoadingDownload(load)
            axios.get(process.env.REACT_APP_API + '/invoices/download/' + invoice.id + '/' + user.id + '/' + project.id, {
                responseType: 'blob',
                headers: {
                    // 'Accept': 'application/octet-stream',
                    'Content-Type':  invoice.type === 'smartbill' ? 'application/xml' : 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    invoice.series + invoice.number + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);


                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)

                    setLoadingDownload([])
                })
        }


    }

    const sendToClient = async (id, status) => {

        let payload = {
            status: status,
        }
        await axios.put(process.env.REACT_APP_API + '/invoices/send-to-client/' + id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                getData()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const deleteInvoice = async (id) => {

        await axios.delete(process.env.REACT_APP_API + '/invoices/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                    setLoadingDelete([])
                } else {
                    if (response.data.error)
                        toast.error(response.data.error)
                    else {
                        toast.error(intl.formatMessage({id: 'errors.invoice_cannot_delete'}))
                    }
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const cancelInvoice = async (id) => {

        await axios.get(process.env.REACT_APP_API + '/invoices/cancel/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    getData()
                    setLoadingCancel([])
                } else {
                    toast.error(response.data.error)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const toggleSelectInvoice = (invoiceId) => {

        if (selectedInvoices.includes(invoiceId)) {
            setSelectedInvoices(selectedInvoices.filter((item) => item !== invoiceId))
        } else {
            setSelectedInvoices([...selectedInvoices, invoiceId])
        }

    }

    const collectInvoice = async (e) => {
        e.preventDefault()
        setLoadingCollect(true)
        axios.post(process.env.REACT_APP_API + '/invoices/collect/' + invoiceSelected.id, {
            collect,
            number: docNr,
            series: seriesChit
        }, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1 || response.data.status === 200) {
                    getData()
                } else {
                    toast.error(response.data.error)
                }
                setModalIsOpenCollect(false)
                setLoadingCollect(false)
            })
            .catch(err => {
                setModalIsOpenCollect(false)
                errorParser(err, intl)
                setLoadingCollect(false)
            })
    }
    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/invoices/series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {

                if (response.data.error) {
                    toast.error(response.data.error)
                } else {
                    let temp = []
                    response.data.data.forEach((item) => {
                        if (item.type === 'Chitanta' || item.type === 'c')
                            temp.push({
                                value: item.name,
                                label: item.name,
                                number: item.next,
                            })
                    })
                    setOptions(temp)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    return (
        <div className={"content p-0"}>
            <div className={"table mb-1"}>
                <div className={"table-head"} style={{minWidth: project === null ? '1000px' : '700px'}}>
                    <div className={"row"}>
                        <div
                            className={"col-2 table-td"}>
                            <input type={"checkbox"}
                                   onChange={() => {
                                       if (selectedInvoices.length === invoices.length) {
                                           setSelectedInvoices([])
                                       } else {
                                           setSelectedInvoices(invoices.map((item) => item.id))
                                       }
                                   }}
                                   value={'all'}
                                   checked={selectedInvoices.length > 0 && selectedInvoices.length === invoices.length}
                            /></div>

                        <div
                            className={"col-2 table-td"}>
                            {intl.formatMessage({id: 'invoices.generate_with'})}
                        </div>
                        <div
                            className={"table-td col-1"}>{intl.formatMessage({id: 'general.date'})}</div>
                        {
                            project === null &&
                            <div
                                className={"table-td col-2"}>{intl.formatMessage({id: 'general.client'})}</div>}
                        <div className={"table-td " + (project === null ? 'col-5' : 'col-7')}></div>
                    </div>
                </div>


                <div className={"table-body"} style={{minWidth: project === null ? '1000px' : '700px'}}>
                    {
                        invoices.map((invoice, i) => (
                            <div className={"table-row"} key={i}>
                                <div className={"row"}>
                                    <div
                                        className={"col-2 table-td"}>
                                        <input type={"checkbox"} className={"me-2"}
                                               onChange={() => toggleSelectInvoice(invoice.id)}
                                               value={invoice.id}
                                               checked={selectedInvoices.includes(invoice.id)}/>

                                        {invoice.series}{invoice.number}
                                    </div>
                                    <div
                                        className={"col-2 table-td"}>

                                        {capitalizeFirstLetter(invoice.type)}
                                    </div>
                                    <div
                                        className={"table-td col-1"}>
                                        {moment(invoice.date).format('DD/MM/YY')}
                                    </div>

                                    {
                                        project === null && <div
                                            className={"table-td col-2"}>
                                            {invoice.data.client.name}
                                        </div>}
                                    <div
                                        className={"table-td " + (project === null ? 'col-5' : 'col-7')}>
                                        <Button
                                            className={"btn-light btn-edit ms-auto"}
                                            icon={"fa-solid fa-download"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                            whiteLoader={false}
                                            loading={loadingDownload[invoice.id] ?? false}
                                            onClick={() => download(invoice, invoice.project)}
                                        />
                                        <Button
                                            className={"btn-light btn-delete " + (invoice.canceled ? 'disabled' : '')}
                                            icon={"fa-solid fa-ban"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.cancel'})}
                                            whiteLoader={false}
                                            disabled={invoice.canceled}
                                            loading={loadingCancel[invoice.id] ?? false}
                                            onClick={() => cancelInvoice(invoice.id)}
                                        />
                                        <Button
                                            className={"btn-light btn-delete"}
                                            icon={"fa-solid fa-trash-alt"}
                                            tooltip={intl.formatMessage({id: 'general.buttons.delete'})}
                                            whiteLoader={false}
                                            disabled={loadingDelete[invoice.id]}
                                            loading={loadingDelete[invoice.id] ?? false}
                                            onClick={() => deleteInvoice(invoice.id)}
                                        />
                                        <div className={"ms-2 d-grid"}>
                                            <Button
                                                disabled={invoice.payed}
                                                value={intl.formatMessage({id: 'general.buttons.payment'})}
                                                className={"btn-primary align-items-center ms-auto float-right " + (invoice.payed ? 'disabled' : '')}
                                                onClick={() => {
                                                    if (invoice.type === 'oblio') {
                                                        setInvoiceSelected(invoice)
                                                        setModalIsOpenCollect(true)
                                                    } else if (invoice.type === 'smartbill') {
                                                        setInvoiceSelected(invoice)
                                                        setModalIsOpenCollect(true)
                                                    }

                                                }}
                                            />
                                        </div>
                                        {invoice.client && invoice.client.email && <div className={"ms-2 d-grid"}>
                                            <Button
                                                //disabled={invoice.status !== 'new'}
                                                value={intl.formatMessage({id: 'general.buttons.send_to_client'})}
                                                className={"btn-primary align-items-center ms-auto float-right" /*+ (invoice.status !== 'new' ? 'disabled' : '')*/}
                                                onClick={() => sendToClient(invoice.id, 'send')}
                                            />
                                            {
                                                invoice.email_sent_at &&
                                                <div className={"email-sent-at"}>
                                                    {intl.formatMessage({id: 'projects.email_sent_at'})} {moment(invoice.email_sent_at).format('Do/MM/YY H:mm')}
                                                </div>
                                            }
                                        </div>}

                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>


            <ModalComp
                title={intl.formatMessage({id: 'invoices.collect_bill'})}
                isOpen={modalIsOpenCollect}
                className={"sign-contract"}
                close={true}
                onRequestClose={() => setModalIsOpenCollect(false)}
            >

                <form onSubmit={(e) => collectInvoice(e)}>
                    <div className={"content"} style={{paddingBottom: '200px'}}>
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'invoices.doc_type'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                options={invoiceSelected.type === 'oblio' ? [...optionsOblio] : [...optionsSmartbill]}
                                required={true}
                                value={collect}
                                onChange={(option) => {
                                    setCollect(option.value)
                                }}
                                isSearchable={false}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                        {collect === 'Chitanta' && options.length === 0 &&
                            <div
                                className={"alert alert-warning mt-2"}>{intl.formatMessage({id: 'invoice.cannot_collect_invoice'})} {invoiceSelected.type}!</div>
                        }
                        {collect === 'Chitanta' ?
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoices.receipt_series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={[...options]}
                                    required={true}
                                    onChange={(option) => {
                                        setSeriesChit(option.value)
                                    }}
                                    isSearchable={false}
                                />
                            </div>
                            :
                            invoiceSelected.type === 'oblio' &&
                            <div>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'invoice.doc_number'})}<span
                                    className={"form-required"}>*</span></div>

                                <input type={"text"} className={"form-input"} id={"sdfdsdsf"}
                                       required={true}
                                       onChange={(e) => setDocNr(e.target.value)}/>
                            </div>
                        }

                    </div>
                    <div className={"footer"}>
                        <div className={"row"}>
                            <div className={"col-12 d-flex"}>
                                <Button
                                    loading={loadingCollect}
                                    className={"btn-primary mt-4 ms-auto"}
                                    value={intl.formatMessage({id: 'invoices.generate'})}
                                    type={'submit'}
                                />
                            </div>
                        </div>

                    </div>
                </form>

            </ModalComp>
        </div>
    );
}

