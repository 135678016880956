import {useIntl} from "react-intl";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import Button from "../../../components/elements/Button/Button";
import ContractsTable from "../../../components/ContractsTable/ContractsTable";
import Paginate from "../../../components/elements/Paginate/Paginate";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";

export default function ContractsList() {

    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')

    const [contracts, setContracts] = useState([])

    const [loading, setLoading] = useState(true)
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [search, setSearch] = useState('')
    const [date, setDate] = useState()
    const [selectedContracts, setSelectedContracts] = useState([])
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [user, setUser] = useState(null)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, search, date])

    const getData = async () => {
        let temp = moment(date).add(5, "hours").format("YYYY-MM-DD")
        axios.post(process.env.REACT_APP_API + '/contracts/list/' + page, {search, date: date ? temp : ''}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContracts(response.data.contracts ?? [])
                setTotal(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const downloadContracts = async () => {
        let text = '';
        selectedContracts.forEach((contr, i) => {
            text += contr;
            if (i + 1 !== selectedContracts.length) {
                text += ','
            }
        })


        setLoadingDownload(true)
        axios.get(process.env.REACT_APP_API +'/contracts/download-bulk/' + user.id + '/' + user.company.id + '?contracts=' + text, { })
            .then(response => {
                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'openLink',
                        url: response.data.file,
                    }))

                } else {

                    var a = document.createElement('a');
                    a.href = response.data.file;
                    a.target=''
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    a.remove();  //afterwards we remove the element again
                }
                setLoadingDownload(false)
            })
            .catch(e => console.log(e))

    }
    useEffect(() => {
        getUser()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getUser = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUser(response.data ?? [])
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'contracts.title'})}</h1>
                </div>
                <div className={"col-lg-12"}>
                    <div className="card contracts-list">
                        <div className={"header"}>

                            <div className={"row"}>
                                <div className={"col-lg-3 col-md-6 col-sm-12"}>
                                    <div className={"search-input-container"}>
                                        <i className="fa-solid fa-magnifying-glass"></i>
                                        <input type={"text"} className={"form-input"}
                                               placeholder={intl.formatMessage({id: 'general.search'})}
                                               value={search} onChange={(e) => setSearch(e.target.value)}/>
                                    </div>
                                </div>
                                <div className={"col-lg-3 col-md-6 col-sm-12"}>
                                    <div className={"form-control"}>
                                        <DatePicker
                                            locale={moment.locale()}
                                            placeholderText={intl.formatMessage({id: 'general.date'})}
                                            className={"form-input"}
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                            requires
                                        />
                                    </div>
                                </div>
                                <div className={"col-lg-6 col-sm-12 float-right"}>
                                    <Button
                                        className={"btn-primary d-flex align-items-center ms-3 " + (selectedContracts.length === 0 ? 'disabled' : '')}
                                        icon={"fa-solid fa-download"}
                                        style={{float: 'right'}}
                                        loading={loadingDownload}
                                        value={intl.formatMessage({id: "general.buttons.download"})}
                                        onClick={() => downloadContracts()}
                                        disabled={selectedContracts.length === 0 || loadingDownload}
                                    />
                                </div>
                            </div>
                        </div>


                        {
                            contracts.length === 0 ?
                                <div className={"content"}>

                                    <div className={"alert alert-warning"}>{intl.formatMessage({id: 'contracts.alert_no_contracts_found'})}</div>
                                </div>
                                :
                                <ContractsTable
                                    contracts={contracts}
                                    selectedContracts={selectedContracts}
                                    setSelectedContracts={setSelectedContracts}
                                />
                        }

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={total}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

