import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../components/elements/LoadingComp/LoadingComp";
import './Requests.scss'
import Button from "../../components/elements/Button/Button";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";

export default function RequestStatusCheck() {

    const intl = useIntl();
    const navigate = useNavigate()

    const [code, setCode] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        localStorage.removeItem('jwt')
        localStorage.removeItem('role')
        localStorage.removeItem('access')
        localStorage.removeItem('subscription_expiration_date')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const checkStatus = async (e) => {
        e.preventDefault()
        axios.get(process.env.REACT_APP_API + '/project-requests/find-by-code/' + code, {
            headers: {
            }
        })
            .then(async (response) => {
                if (response.data.status){
                    navigate('/request/' + code)
                }else{
                    toast.error(intl.formatMessage({id: 'errors.request_not_found'}))
                }
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className={"login"}>
            <div className={"logo"}>
                <img src={"/images/logo.svg"} alt={""}/>
            </div>
                <form
                    onSubmit={checkStatus}>
                    <div className={"container"}>
                        <div className={"form-label"}>{intl.formatMessage({id: 'general.request_code'})}</div>
                        <input type={"text"}
                               className={"form-input"}
                               placeholder={intl.formatMessage({id: 'general.request_code'})}
                               value={code}
                               required
                               onChange={(e) => setCode(e.target.value)}/>

                        <Button
                            className={"btn-primary mt-4"}
                            value={intl.formatMessage({id: 'general.check_status'})}
                            type={"submit"}
                            loading={loading}
                        />

                    </div>
                </form>
        </div>
    );
}

