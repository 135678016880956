import {useIntl} from "react-intl";
import React from "react";
import '../Requests.scss'
import UploadDocumentsForm from "./UploadDocumentsForm";
import {useOutletContext, useParams} from "react-router-dom";

export default function UploadDocuments() {

    const intl = useIntl();
    let params = useParams();
    const {context} = useOutletContext()

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'requests.upload_documents'})}</p>
                    </div>
                </div>
            </div>

            <UploadDocumentsForm getDataLink={'/project-requests/' + params.id} saveLink={'/project-requests/upload-documents/' + params.id} context={context}/>

        </div>
    );
}

