import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useOutletContext} from "react-router-dom";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../components/elements/Selector/Selector";
import ModalComp from "../../../../components/ModalComp/ModalComp";
import {delay} from "../../../../utils/functions";
import {CheckPicker} from "rsuite";
// eslint-disable-next-line
import '../../../../styles/check_picker.css';
import AddProductForm from "../../Products/AddProductForm";

export default function ModalAddProductInOffer({
                                                   products,
                                                   setProducts,
                                                   modalIsOpen,
                                                   setModalIsOpen,
                                                   updateTotal,
                                                   index = null,
                                               }) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [productId, setProductId] = useState(null)
    const [product, setProduct] = useState(null)
    const [price, setPrice] = useState(0)
    const [qty, setQty] = useState(0)
    const [productsOptions, setProductsOptions] = useState([])
    const [itemsOptions, setItemsOptions] = useState([])
    const [items, setItems] = useState([])
    const [showAddNewProductModal, setShowAddNewProductModal] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        getProducts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        if (index !== null){
            setQty(products[index].qty)
            setPrice(products[index].price)
           setProduct({
               value: products[index].product.id,
               label: products[index].product.title,
               price: products[index].product.price,
               unit: products[index].product.unit,
               items: products[index].product.items,
               vat: products[index].product.vat
           })
           setProductId(products[index].product.id)
            let temp = []
            products[index].product.items.forEach(item => {
                temp.push({
                    label: item.title,
                    value: item.title
                })
            })
            setItemsOptions(temp)
            setItems(products[index].items)
        }else {
            setQty(1)
            setPrice(0)
            setProduct(null)
            setProductId(null)
            setItems([])
            setItemsOptions([])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalIsOpen])
    const getProducts = async (item = null) => {
        axios.post(process.env.REACT_APP_API + '/products/list/0', {},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let options = []
                response.data.products.forEach((item) => {
                    options.push({
                        value: item.id,
                        label: item.title,
                        price: item.price,
                        unit: item.unit,
                        items: item.items,
                        vat: item.vat
                    })
                })
                setProductsOptions(options)
                if (item){
                    await setProductFromLastAdded(item)
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const addElement = async (e) => {
        e.preventDefault()
        if (price !== 0 && qty !== 0 && product !== null) {

            let temp = [...products]
            let item = {
                product,
                product_id: product.value,
                title: product.label,
                vat: product.vat,
                qty,
                price,
                items
            }
            if (index !== null){
                item.id = temp[index].id
                temp[index] = item
            }else {
                temp.push(item)
            }

            setProducts(temp)
            context.setContentUpdated(true)
            setModalIsOpen(false)
            await delay(1000);
            updateTotal(temp)
        }
    }
    const deleteElement = async () => {
        let temp = [...products]
        temp.splice(index, 1)
        setProducts(temp)
        context.setContentUpdated(true)
        setModalIsOpen(false)
        await delay(1000);
        updateTotal(temp)
    }
    const setProductFromLastAdded = async (item) => {
        setProductId(item.id)
        setProduct({
            value: item.id,
            label: item.title,
            price: item.price,
            unit: item.unit,
            items: item.items,
            vat: item.vat
        })
        setPrice(item.price)
        setQty(1)
        let temp = []
        item.items.forEach(item => {
            temp.push({
                label: item.title,
                value: item.title
            })
        })
        setItemsOptions(temp)
        setItems(item.items.map(item2 => item2.title))
        setShowAddNewProductModal(false)
    }

    return (
    <>
        <ModalComp
            title={<div>
                {intl.formatMessage({id: 'general.offer.add_product_in_offer'})}
                <Button
                    className={"btn-primary ms-auto"} style={{float: 'right'}}
                    value={intl.formatMessage({id: 'general.add_new_product'})}
                    type={'button'}
                    onClick={() =>{
                        setShowAddNewProductModal(true)
                    }}
                />
            </div>}
            isOpen={modalIsOpen}
            className={"sign-contract"}
            close={true}
            onRequestClose={() => setModalIsOpen(false)}
        >
            <form onSubmit={(e) => addElement(e)}>
                <div className={"content"} style={{paddingBottom: '200px'}}>
                    <div>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: "general.product"})}<span
                            className={"form-required"}>*</span></div>
                        <Selector
                            options={productsOptions}
                            required={true}
                            key={productId}
                            value={productId}
                            onChange={(option) => {
                                setProductId(option.value)
                                setProduct(option)
                                setPrice(option.price)
                                setItems([])
                                let temp = []
                                option.items.forEach(item => {
                                    temp.push({
                                        label: item.title,
                                        value: item.title
                                    })
                                })
                                setItemsOptions(temp)
                            }}
                            isSearchable={true}
                            isOptionDisabled={(option) => option.disabled}
                        />
                    </div>

                    <div>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: "general.qty"})}<span
                            className={"form-required"}>*</span></div>

                        <input type={"text"} className={"form-input"}
                               required={true} value={qty}
                               onChange={(e) => setQty(e.target.value)}/>
                    </div>

                    {product !== 0 &&
                        <div>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: "general.price"})}<span
                                className={"form-required"}>*</span></div>

                            <input type={"text"} className={"form-input"}
                                   required={true} value={price}
                                   onChange={(e) => setPrice(e.target.value)}/>
                        </div>}
                    {product && itemsOptions && itemsOptions.length > 0 && <div>
                        <div
                            className={"form-label"}>{intl.formatMessage({id: "general.products.included_items"})}</div>
                        <CheckPicker
                            data={itemsOptions}
                            defaultValue={items}
                            value={items}
                          //  key={items.length}
                            style={{width: '100%'}}
                            onChange={(option) => {
                                setItems(option)
                            }}
                            isSearchable={true}
                        />
                    </div>}
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-6 d-flex"}>
                            {index !== null && <Button
                                className={"btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.buttons.delete'})}
                                type={'button'}
                                onClick={() => {
                                    deleteElement()
                                }}
                            />}
                        </div>
                        <div className={"col-12 col-md-6 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.buttons.' + (index !== null ? 'save' : 'add')})}
                                type={'submit'}
                            />
                        </div>
                    </div>

                </div>
            </form>
        </ModalComp>
        <ModalComp
            title={intl.formatMessage({id: 'general.sidebar.products'})}
            isOpen={showAddNewProductModal}
            className={"sign-contract"}
            close={true}
            onRequestClose={() => setShowAddNewProductModal(false)}
        >
            <AddProductForm id={0} showBackButton={false} isOnSave={true} onSave={async (item) => {
                await getProducts(item)
            }}/>
        </ModalComp>
    </>
    );
}

