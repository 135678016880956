import Sidebar from "./Sidebar/Sidebar";
import {Outlet, useNavigate} from "react-router-dom";
import './Layout.scss';
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import LoadingComp from "../elements/LoadingComp/LoadingComp";
import Notification from "../elements/Notification/Notification";
import {Tooltip} from "react-tooltip";
import ModalComp from "../ModalComp/ModalComp";
import Button from "../elements/Button/Button";
import moment from "moment";

export default function Layout() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [sidebarMini, setSidebarMini] = useState(false);
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [notificationsShow, setNotificationsShow] = useState(false);
    const [user, setUser] = useState(null)
    const [loading, setLoading] = useState(true)
    const [items, setItems] = useState([])
    const [notification, setNotification] = useState(null);
    const [notifications, setNotifications] = useState(0);
    const [contentUpdated, setContentUpdated] = useState(false)
    const [leavePage, setLeavePage] = useState(false)
    const [nextLink, setNextLink] = useState('')
    const [loadingNotification, setLoadingNotification] = useState(false)

    useEffect(() => {
        getData(0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        if (contentUpdated === false && nextLink !== '') {
            setLeavePage(false)
            navigate(nextLink)
            setNextLink('')

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextLink, leavePage])

    const getData = async (updateNotification) => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let itemsTemp = []
                setUser(response.data ?? [])
                if (response.data) {
                    localStorage.setItem('access', JSON.stringify(response.data.access))
                }
                if (response.data.notification && parseInt(response.data.role.id) === 1 && updateNotification === 0) {
                    setNotification(response.data.notification)
                } else {
                    setNotification(null)
                }
                if (response.data.notifications) {
                    setNotifications(response.data.notifications)
                    if (response.data.notifications.length === 0) {
                        setNotificationsShow(false)
                    }
                }
                if (response.data.company && response.data.company.currency) {
                    localStorage.setItem('currency', response.data.company.currency)
                }
                if (response.data.company && response.data.company.subscription_expiration_date) {
                    localStorage.setItem('subscription_expiration_date', response.data.company.subscription_expiration_date)
                    if (moment(response.data.company.subscription_expiration_date).unix() < moment().unix() && !window.location.href.includes('/subscription-expired')){
                        window.location.href = '/subscription-expired'
                    }
                }
                if (response.data.role.id && parseInt(response.data.role.id) === 4) {
                    itemsTemp = [
                        {
                            path: '/company',
                            icon: 'fa-solid fa-cog',
                            title: intl.formatMessage({id: 'general.sidebar.companies'}),
                            disabled: false
                        },
                        {
                            path: '/users',
                            icon: 'fa-solid fa-users',
                            title: intl.formatMessage({id: 'settings.menu.users'}),
                            disabled: false
                        },
                        {
                            icon: "fa-solid fa-user",
                            title: intl.formatMessage({id: 'settings.menu.account'}),
                            path: '/',
                            end: 1,
                            disabled: false
                        }
                    ]
                } else {
                    if (response.data.role.id && parseInt(response.data.role.id) === 1) {

                            itemsTemp = [

                                {
                                    path: '/requests',
                                    url: '/requests',
                                    icon: 'fa-solid fa-bell',
                                    title: intl.formatMessage({id: 'general.sidebar.requests'}),
                                },
                                {
                                    path: '/projects',
                                    url: '/projects',
                                    icon: 'fa-solid fa-list-check',
                                    title: intl.formatMessage({id: 'general.sidebar.projects'}),
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-rectangle-list',
                                    title: intl.formatMessage({id: 'general.sidebar.nomenclature'}),
                                    items: [
                                        {
                                            path: '/clients',
                                            url: '/clients',
                                            icon: 'fa-solid fa-user',
                                            title: intl.formatMessage({id: 'general.sidebar.clients'}),
                                            disabled: !(response.data.access && response.data.access.clients)
                                        },
                                        {
                                            path: '/products',
                                            url: '/products',
                                            icon: 'fa-solid fa-boxes',
                                            title: intl.formatMessage({id: 'general.sidebar.products'}),
                                            disabled: !(response.data.access && response.data.access.clients)
                                        },
                                        {
                                            'icon': "fa-solid fa-file-pen",
                                            'title': intl.formatMessage({id: 'general.sidebar.templates'}),
                                            path: '/templates',
                                            url: '/templates',
                                            disabled: !(response.data.access && response.data.access.templates)
                                        },
                                    ]
                                },
                                /*{
                                    path: '#',
                                    icon: 'fa-solid fa-boxes-stacked',
                                    title: intl.formatMessage({id: 'general.sidebar.finance'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-scale-balanced",
                                            title: intl.formatMessage({id: 'general.sidebar.offers'}),
                                            path: '/offers/list',
                                          //  disabled: !(response.data.access && response.data.access.offers)
                                        },
                                        {
                                            path: '/invoices',
                                            icon: 'fa-regular fa-file-lines',
                                            title: intl.formatMessage({id: 'general.sidebar.invoices'}),
                                         //   disabled: !(response.data.access && response.data.access.invoices)
                                        },
                                        {
                                            path: '/contracts',
                                            icon: 'fa-regular fa-file',
                                            title: intl.formatMessage({id: 'general.sidebar.contracts'}),
                                         //   disabled: !(response.data.access && response.data.access.contracts)
                                        },
                                    ]
                                },*/
                                   {
                                         path: '#',
                                         icon: 'fa-solid fa-boxes-stacked',
                                         title: intl.formatMessage({id: 'general.sidebar.finance'}),
                                         items: [
                                      {
                                                 icon: "fa-solid fa-scale-balanced",
                                                 title: intl.formatMessage({id: 'general.sidebar.offers'}),
                                                 path: '/offers',
                                                 disabled: !(response.data.access && response.data.access.offers)
                                             },
                                             {
                                                 path: '/invoices',
                                                 icon: 'fa-regular fa-file-lines',
                                                 title: intl.formatMessage({id: 'general.sidebar.invoices'}),
                                                 disabled: !(response.data.access && response.data.access.invoices)
                                             },
                                         {
                                            path: '/contracts',
                                            icon: 'fa-regular fa-file',
                                            title: intl.formatMessage({id: 'general.sidebar.contracts'}),
                                            disabled: !(response.data.access && response.data.access.contracts)
                                        },
                                    ]
                                },
                                {
                                    path: '#',
                                    icon: 'fa-solid fa-gears',
                                    title: intl.formatMessage({id: 'general.sidebar.settings'}),
                                    items: [
                                        {
                                            icon: "fa-solid fa-user",
                                            title: intl.formatMessage({id: 'settings.menu.account'}),
                                            path: '/',
                                        },
                                        {
                                            icon: "fa-solid fa-cog",
                                            title: intl.formatMessage({id: 'general.sidebar.company'}),
                                            path: '/company',
                                            url: '/company/edit',
                                            disabled: !(response.data.access && response.data.access.company),
                                        },
                                        {
                                            icon: "fa-solid fa-users",
                                            title: intl.formatMessage({id: 'settings.menu.users'}),
                                            path: '/users',
                                            disabled: !(response.data.access && response.data.access.users),
                                        },
                                   /*     {
                                            icon: "fa-solid fa-bell",
                                            title: intl.formatMessage({id: 'settings.menu.notifications'}),
                                            path: '/notifications',
                                            url: '/notifications/configuration',
                                         //   disabled: !(response.data.access && response.data.access.notifications)
                                        },*/
                                    ]
                                }
                            ]
                    }

                    itemsTemp.push({
                        end: 1,
                        path: '/bugs',
                        icon: 'fa-solid fa-bug',
                        title: intl.formatMessage({id: 'general.sidebar.bugs'})
                    })
                }
                setItems(itemsTemp)
                setLoading(false)

                if (window.alityAppView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({
                        action: 'getPushToken',
                        data: jwt
                    }))
                }

            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return (<LoadingComp/>);
    }
    return (
        <div>
            {notificationsShow && <div onClick={() => {
                setNotificationsShow(false)
            }} className={"backgroundNotification"}>
            </div>}
            <div className={"navbar"}>
                <div className={"icon-sidebar d-flex"}>
                    {
                        window.innerWidth <= 576 ?
                            <div onClick={() => setMenuIsOpen(!menuIsOpen)}>
                                {
                                    menuIsOpen ?
                                        <i className="fa-solid fa-bars"></i>
                                        :
                                        <i className="fa-solid fa-bars"></i>
                                }
                            </div>

                            :
                            <div onClick={() => setSidebarMini(!sidebarMini)} style={{position: 'relative'}}>
                                {
                                    sidebarMini ?
                                        <img src={"/images/icons/menu-unfold.svg"} alt={""} data-tooltip-id={"collapse-sidebar"}/>
                                        :
                                        <img src={"/images/icons/menu-fold.svg"} alt={""} data-tooltip-id={"collapse-sidebar"}/>

                                }

                                <Tooltip id={"collapse-sidebar"}>
                                    {!sidebarMini ?
                                        intl.formatMessage({id: 'general.sidebar.collapse'})
                                        :

                                        intl.formatMessage({id: 'general.sidebar.expand'})}
                                </Tooltip>
                            </div>
                    }

                    {notifications.length > 0 &&
                        <i data-tooltip-id={"notifications"} className="fa-solid fa-bell ms-auto"
                           style={{marginTop: '3px'}} onClick={() => {
                            getData(1)
                            setMenuIsOpen(false)
                            setNotificationsShow(!notificationsShow)

                        }}></i>}

                    <Tooltip id={"notifications"}>
                        {intl.formatMessage({id: 'settings.menu.notifications'})}
                    </Tooltip>

                </div>
                {
                    notificationsShow &&
                    <div className={"notifications-pop-up"}>
                        {
                            notifications.map((notification) => (
                                <Notification
                                    notification={notification}
                                    notificationsShow={notificationsShow}
                                    setNotificationsShow={setNotificationsShow}
                                    getData={() => {
                                        getData(1)
                                    }}
                                />
                            ))
                        }
                    </div>
                }
            </div>
            <div className={"layout"}>
                <Sidebar
                    sidebarMini={sidebarMini}
                    menuIsOpen={menuIsOpen}
                    setLoadingNotification={setLoadingNotification}
                    loadingNotification={loadingNotification}
                    setSidebarMini={setSidebarMini}
                    setMenuIsOpen={setMenuIsOpen}
                    notification={notification}
                    setNotification={setNotification}
                    items={items}
                    loading={loading}
                    user={user}
                    getData={getData}
                    notifications={notifications}
                    context={{setLeavePage, setContentUpdated, setNextLink, contentUpdated}}
                />
                <Outlet context={{context: {setLeavePage, setContentUpdated, setNextLink,contentUpdated}}}/>
            </div>
            <ModalComp
                title={intl.formatMessage({id: 'general.unsaved_changes'})}
                isOpen={contentUpdated && leavePage}
                className={"sign-contract"}
                close={false}
            >
                <div className={"content"}>

                    <div>
                        {intl.formatMessage({id: 'general.leave_page'})}
                    </div>
                </div>

                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-12"}>
                            <Button
                                className={"btn-light btn-error mt-4"}
                                value={intl.formatMessage({id: 'general.yes'})}
                                onClick={() => {
                                    navigate(nextLink)
                                    setLeavePage(false)
                                    setContentUpdated(false)
                                }}
                                type={'button'}
                            />
                        </div>
                        <div className={"col-md-6 col-12 d-flex"}>
                            <Button
                                className={"btn-primary mt-4 ms-auto"}
                                value={intl.formatMessage({id: 'general.no'})}
                                type={'button'}
                                onClick={() => {
                                    setLeavePage(false)
                                }}
                            />
                        </div>
                    </div>

                </div>

            </ModalComp>
        </div>
    );
}

