import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import '../Requests.scss'
import moment from "moment";

export default function RequestLogs() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')

    const [logs, setLogs] = useState('')

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/project-requests/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setLogs(response.data.logs)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'requests.actions_history'})}</p>
                    </div>
                </div>
            </div>

            <div className={"content p-0"}>
                <div className={"table mb-1"}>

                    <div className={"table-head"}>
                        <div className={"row"}>
                            <div
                                className={"col-1 table-td"}>{intl.formatMessage({id: 'general.nr'})}</div>

                            <div
                                className={"col-9 table-td"}>{intl.formatMessage({id: 'general.type'})}</div>
                            <div
                                className={"col-2 table-td"}>{intl.formatMessage({id: 'general.date'})}</div>
                        </div>
                    </div>


                    <div className={"table-body"}>
                        {
                            logs.map((log, i) => (
                                <div className={"table-row"} key={i}>
                                    <div className={"row"}>
                                        <div
                                            className={"col-1 table-td"}>
                                            {i + 1}
                                        </div>
                                        <div
                                            className={"col-9 table-td"}>
                                            {intl.formatMessage({id: 'requests.logs.type.' + log.type})}
                                        </div>
                                        <div
                                            className={"col-2 table-td"}>
                                            {moment(log.createdAt).format('Do/MM/YYYY H:mm')}
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>

            <div className={"footer d-flex justify-content-center"}>
            </div>


        </div>
    );
}

