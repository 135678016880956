import {useIntl} from "react-intl";
import React, {useState} from "react";
import '../Requests.scss'
import UploadFile from "../../../../components/Upload/UploadFile";

export default function FileContainer({request,fileKey, file, setFile,multiple=false}) {

    const intl = useIntl();
    const [showUploadField, setShowUploadField] = useState(request[fileKey] ? false : true)

    return (
        <div className={"col-12"}>
            <div
                className={"form-label mt-3"}>{intl.formatMessage({id: 'requests.' + fileKey})}<span className={"form-required"}>*</span>
            </div>
            {
                request && request[fileKey] &&
                    <div className={"row mb-3"}>
                        <div className={"col-md-6 col-sm-12"}>
                            {
                                multiple ?
                                    <div className={"alert alert-success"} style={{cursor: "pointer"}}
                                    onClick={() =>{
                                        request[fileKey].forEach(item => {
                                            var link = document.createElement("a");
                                            link.download = item.url.split('/')[2];
                                            link.target = "_blank"
                                            link.href = process.env.REACT_APP_API.replace('/api', '') + item.url;
                                            document.body.appendChild(link);
                                            link.click();
                                            document.body.removeChild(link);
                                        })
                                    }}
                                    >{intl.formatMessage({id: 'general.buttons.download'})}</div>
                                    :
                                    <a href={process.env.REACT_APP_API.replace('/api', '') + request[fileKey].url}
                                       target={"_blank"} rel={"noreferrer"}>
                                        <div className={"alert alert-success"}>{intl.formatMessage({id: 'general.buttons.download'})}</div>
                                    </a>
                            }

                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"alert alert-info"} style={{cursor: "pointer"}}
                                 onClick={() => setShowUploadField(true)}>{intl.formatMessage({id: 'requests.reload_document'})}</div>
                        </div>
                    </div>
            }
            {
                showUploadField &&
                <UploadFile
                    multiple={multiple}
                    file={file}
                    setFile={setFile}
                />
            }
        </div>
    );
}

