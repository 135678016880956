import {useIntl} from "react-intl";
import React from "react";
import {useParams} from "react-router-dom";

export default function ProjectDetails() {

    const intl = useIntl();
    let params = useParams();

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'clients.edit_client'})}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

