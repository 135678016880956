import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import {useOutletContext} from "react-router-dom";
import ModalChangeEmail from "../../../../components/ModalChangeEmail/ModalChangeEmail";
import ModalDeleteAccount from "../../../../components/ModalDeleteAccont/ModalDeleteAccount";

export default function Account() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')

    const [changePassword, setChangePassword] = useState(false)
    const [password, setPassword] = useState('')
    const [repeatPassword, setRepeatPassword] = useState('')

    const [loading, setLoading] = useState(true)
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const [modalChangeEmailIsOpen, setModalChangeEmailIsOpen] = useState(false)
    const {context} = useOutletContext()

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalChangeEmailIsOpen])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users/me', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setFirstName(response.data.first_name)
                setLastName(response.data.last_name)
                setEmail(response.data.email)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (e) => {

        e.preventDefault();
        //Validation

        if (changePassword) {
            if (!password || !repeatPassword || password !== repeatPassword) {
                toast.error(intl.formatMessage({id: 'errors.passwords_not_match'}))
                return
            }
        }

        setSaveLoading(true)

        let formData = new FormData()

        formData.append('first_name', firstName)
        formData.append('last_name', lastName)
        if (changePassword) {
            formData.append('password', password)
        }
        await axios.put(process.env.REACT_APP_API + '/users/updateProfile', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container my-account">

            <div className={"row"}>
                <div className={"col-md-4 col-sm-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.account'})}</h1>
                </div>
                <div className={"col-md-8 col-sm-12 d-flex"}>
                    <Button
                        className={"btn-error d-flex align-items-center ms-auto"}
                        value={intl.formatMessage({id: 'settings.request_delete_account'})}
                        onClick={() => setModalIsOpen(true)}
                    />
                    <Button
                        className={"btn-primary d-flex align-items-center ms-2"}
                        value={intl.formatMessage({id: 'general.change_email'})}
                        onClick={() => setModalChangeEmailIsOpen(true)}
                    />
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.account.first_name'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={firstName} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setFirstName(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12 col-md-6"}>
                                        <div className={"form-control"}>
                                            <div className={"form-label"}>{intl.formatMessage({id: 'settings.account.last_name'})}<span
                                                className={"form-required"}>*</span></div>
                                            <input type={"text"} className={"form-input"} required
                                                   value={lastName} onChange={(e) => {
                                                context.setContentUpdated(true)
                                                setLastName(e.target.value)
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                                            <input type={"email"} className={"form-input"} disabled={true}
                                                   value={email} onChange={(e) => {
                                            }}/>
                                        </div>
                                    </div>
                                    <div className={"col-12"}>
                                        <div className={"mt-3 form-control"}>
                                            <label>
                                                <input type={"checkbox"}
                                                       checked={changePassword}
                                                       onChange={() => {
                                                           context.setContentUpdated(true)
                                                           setChangePassword(!changePassword)
                                                       }}/> {intl.formatMessage({id: 'settings.account.change_password'})}
                                            </label>
                                        </div>
                                    </div>
                                    {
                                        changePassword ?
                                            <>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.new_password'})}
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"} required
                                                               value={password} onChange={(e) => {
                                                            context.setContentUpdated(true)
                                                            setPassword(e.target.value)
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className={"col-12 col-md-6"}>
                                                    <div className={"form-control"}>
                                                        <div
                                                            className={"form-label"}>{intl.formatMessage({id: 'settings.account.confirm_new_password'})}
                                                            <span className={"form-required"}>*</span></div>
                                                        <input type={"password"} className={"form-input"}
                                                               value={repeatPassword} required
                                                               onChange={(e) => {
                                                                   context.setContentUpdated(true)
                                                                   setRepeatPassword(e.target.value)
                                                               }}/>
                                                    </div>
                                                </div>
                                            </>
                                            : null
                                    }
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 col-md-6"}>
                                        <Button
                                            className={"btn-light mt-2"}
                                            icon={"fa-solid fa-arrow-right-from-bracket"}
                                            value={intl.formatMessage({id: 'general.buttons.signout'})}
                                            onClick={() => {
                                                if(window.alityAppView) {
                                                    window.ReactNativeWebView.postMessage(JSON.stringify({
                                                        action: 'clearJwt',
                                                        data: ''
                                                    }))
                                                }
                                                localStorage.clear();
                                                window.location.replace("/");
                                            }}
                                        />
                                    </div>
                                    <div className={"col-12 col-md-6 d-flex"}>
                                        <Button
                                            className={"btn-primary mt-2 ms-auto"}
                                            icon={"fa-regular fa-floppy-disk"}
                                            value={intl.formatMessage({id: 'general.buttons.save'})}
                                            type={"submit"}
                                            loading={saveLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

            <ModalDeleteAccount setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}/>
            <ModalChangeEmail modalIsOpen={modalChangeEmailIsOpen} setModalIsOpen={setModalChangeEmailIsOpen}/>
        </div>
    );
}

