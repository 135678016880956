import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import {useParams} from "react-router-dom";

export default function ViewContract() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [contract, setContract] = useState([])

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContract = async (company, client) => {
        axios.get(process.env.REACT_APP_API + '/contracts/download-for-request/' + params.id, {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + jwt,
                'Content-Type': 'application/pdf',
            }
        }).then((response) => {
            const url = window.URL.createObjectURL(
                response.data,
            );
            setContract(url)
            setLoading(false)
        })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <iframe src={contract} className={"pdf-ifrane"} title={'Contract'}>
        </iframe>
    );
}

