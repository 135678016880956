import Button from "../../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import {useNavigate, useOutletContext} from "react-router-dom";
import {toast} from "react-toastify";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import Selector from "../../../../../components/elements/Selector/Selector";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";

export default function AddPlaceForm({clientId,id,showBackButton,isOnSave = false, onSave = () => {}, ended = false}) {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [county, setCounty] = useState(0)
    const [counties, setCounties] = useState([])
    const [city, setCity] = useState('')
    const [address, setAddress] = useState('')
    const [zipcode, setZipcode] = useState('')

    const [loading, setLoading] = useState(true)

    const [saveLoading, setSaveLoading] = useState(false)

    const {context} = useOutletContext()

    useEffect(() => {
        getCounties()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
                if (id !== 0) {
                    getData(temp)
                } else {
                    setLoading(false)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const getData = async (temp = counties) => {
        axios.get(process.env.REACT_APP_API + '/place-of-consumptions/' + id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {

                    let countyFind = temp.find(item => item.label === response.data.county)
                    if (countyFind)
                        setCounty(countyFind.value)
                    setCity(response.data.city ?? '')
                    setAddress(response.data.address ?? '')
                    setZipcode(response.data.zipcode ?? '')
                    setLoading(false)

                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let countyFind = counties.find(item => item.value === county)
        let payload = {
            county: countyFind ? countyFind.label : '',
            city,
            address,
            zipcode,
            client: clientId
        }

        await axios.post(process.env.REACT_APP_API + '/place-of-consumptions' + (id !== 0 ? ('/' + id) : ''), payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                context.setContentUpdated(false)
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))

                    if (isOnSave) {
                        onSave(response.data.place)
                    } else if (id === 0)
                        navigate(-1)
                } else {
                    toast.error(intl.formatMessage({id: 'errors.error.exist_client_with_this_email'}))
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (
        <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-md-6 col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.county'})}<span
                                className={"form-required"}>*</span></div>
                            <Selector
                                required
                                options={counties}

                                value={county}
                                key={county}
                                onChange={(option) => {
                                    context.setContentUpdated(true)
                                    setCounty(option.value)
                                    setCity('')
                                }}
                                isSearchable={true}
                                isOptionDisabled={(option) => option.disabled}
                            />
                        </div>
                    </div>
                    {
                        county === 1 ?
                            <div className={"col-md-6 col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                                        className={"form-required"}>*</span></div>
                                    <Selector
                                        required
                                        options={[{
                                            value: 'Sector 1',
                                            label: 'Sector 1',
                                        }, {
                                            value: 'Sector 2',
                                            label: 'Sector 2',
                                        }, {
                                            value: 'Sector 3',
                                            label: 'Sector 3',
                                        }, {
                                            value: 'Sector 4',
                                            label: 'Sector 4',
                                        }, {
                                            value: 'Sector 5',
                                            label: 'Sector 5',
                                        }, {
                                            value: 'Sector 6',
                                            label: 'Sector 6',
                                        },]}
                                        value={city}

                                        onChange={(option) => {
                                            context.setContentUpdated(true)
                                            setCity(option.value)
                                        }}
                                        isSearchable={true}
                                        isOptionDisabled={(option) => option.disabled}
                                    />
                                </div>
                            </div>
                            :
                            <div className={"col-md-6 col-sm-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                                        className={"form-required"}>*</span></div>
                                    <input type={"text"} className={"form-input"}
                                           value={city}
                                           required
                                           onChange={(e) => {
                                               context.setContentUpdated(true)
                                               setCity(e.target.value)
                                           }}
                                    />
                                </div>
                            </div>}
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.address'})}<span
                                className={"form-required"}>*</span></div>
                            <input type={"text"} className={"form-input"}
                                   value={address}
                                   required
                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setAddress(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.zipcode'})}</div>
                            <input type={"text"} className={"form-input"}
                                   value={zipcode}

                                   onChange={(e) => {
                                       context.setContentUpdated(true)
                                       setZipcode(e.target.value)
                                   }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                        {showBackButton && <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {
                                context.setLeavePage(true)
                                context.setNextLink('/clients/'+clientId+'/places')
                            }}
                        />}
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        {!ended && <Button
                            className={"btn-primary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={"submit"}
                        />}
                    </div>
                </div>
            </div>
        </form>
    );
}

