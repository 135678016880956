import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import moment from "moment";
import {useNavigate} from "react-router-dom";

export default function OffersTable({offers}) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [loadingDownload, setLoadingDownload] = useState([])

    const download = async (offer) => {

        if (window.alityAppView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                action: 'openLink',
                url: process.env.REACT_APP_API +  '/offers/download-for-request/' + offer.id ,
            }))

        } else {
            axios.get(process.env.REACT_APP_API +  '/offers/download-for-request/' + offer.project_request.id, {
                responseType: 'blob',
                headers: {
                    'Authorization': 'Bearer ' + jwt,
                    'Content-Type': 'application/pdf',
                }
            }).then((response) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                    response.data,
                );

                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                    'download',
                    'offer-' + offer.series + offer.number + '.pdf',
                );
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                setLoadingDownload([])
            })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingDownload([])
                })
        }


    }
    return (
        offers.length === 0 ?
            <div className={"content"}>
                <div className={"alert alert-warning"}>
                    {intl.formatMessage({id: 'offers.alert_no_offers'})}
                </div>
            </div>
            :
            <div className={"content p-0"}>
                <div className={"table mb-1"}>

                    <div className={"table-head"}>
                        <div className={"row"}>
                            <div
                                className={"col-1 table-td"}></div>
                            <div
                                className={"table-td col-2"}>{intl.formatMessage({id: 'general.date'})}</div>
                            <div className={"table-td col-3"}>{intl.formatMessage({id: 'general.client'})}</div>
                            <div
                                className={"col-2 table-td"}>{intl.formatMessage({id: 'general.send_to_client'})}</div>
                            <div
                                className={"col-2 table-td"}>{intl.formatMessage({id: 'general.signed_by_client'})}</div>
                            <div className={"table-td col-2"}></div>
                        </div>
                    </div>


                    <div className={"table-body"}>
                        {
                            offers.map((offer, i) => (
                                <div className={"table-row"} key={i}>
                                    <div className={"row"}>
                                        <div
                                            className={"col-1 table-td"}>{offer.series}{offer.number}</div>
                                        <div
                                            className={"table-td col-2"}>
                                            {moment(offer.date).format('DD/MM/YY')}
                                        </div>
                                        <div className={"table-td col-3"}>{offer.project_request.client.type === 'individual' ?
                                            (offer.project_request.client.first_name +' '+ offer.project_request.client.last_name)
                                            : offer.project_request.client.company_title}</div>
                                        <div
                                            className={"col-2 table-td"}>
                                            <i className={offer.sent_to_client ? "fa-solid fa-check-double" : "fa-solid fa-xmark" }
                                               style={{color: offer.sent_to_client ? "green" : "red", margin: "auto"}}/>
                                        </div>
                                        <div
                                            className={"col-2 table-td"}>

                                            <i className={offer.signed_by_client ? "fa-solid fa-check-double" : "fa-solid fa-xmark" }
                                               style={{color: offer.signed_by_client ? "green" : "red", margin: "auto"}}/>
                                        </div>
                                        <div className={"table-td col-2"}>
                                            <Button
                                                className={"btn-light btn-edit ms-auto"}
                                                icon={"fa-solid fa-download"}
                                                whiteLoader={false}
                                                loading={loadingDownload[offer.id] ?? false}
                                                disabled={loadingDownload[offer.id] ?? false}
                                                tooltip={intl.formatMessage({id: 'general.buttons.download'})}
                                                onClick={() => {
                                                    download(offer)
                                                }}
                                            />
                                           <Button
                                                className={"btn-light btn-edit ms-2 "}
                                                icon={"fa-solid fa-bell"}
                                                tooltip={intl.formatMessage({id: 'contracts.view_request'})}
                                                onClick={() => navigate('/requests/'+offer.project_request.id+'/offer')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>


    );
}

