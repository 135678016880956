import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import ModalConfirmDelete from "../../../../components/ModalConfirmDelete/ModalConfirmDelete";

export default function Users() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')

    const [users, setUsers] = useState([])
    const [modalIsOpen, setModalIsOpen] = useState(false)
    const [itemToDelete, setItemToDelete] = useState(0)
    const [loadingDelete, setLoadingDelete] = useState(false)

    const [loading, setLoading] = useState(true)


    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/users', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setUsers(response.data ?? [])
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }


    const deleteUser = async () => {
        setLoadingDelete(true)
        axios.delete(process.env.REACT_APP_API + '/users/' + itemToDelete, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    getData()
                }
                setLoadingDelete(false)
                setModalIsOpen(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'settings.menu.users'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <div className={"header d-flex align-items-center"}>
                            <Button
                                className={"btn-primary ms-auto"}
                                icon={"fa-solid fa-plus"}
                                value={intl.formatMessage({id: 'general.buttons.add'})}
                                onClick={() => navigate('/users/add')}
                            />
                        </div>


                        {
                            users.length === 0 ?
                                <div className={"content"}>
                                    <div className={"alert alert-warning"}>
                                        {intl.formatMessage({id: 'settings.users.alert_no_users'})}
                                    </div>
                                </div>
                                :
                                <div className={"content p-0"}>
                                    <div className={"table mb-1 users-table"}>

                                        <div className={"table-head"}>
                                            <div className={"row"}>
                                                <div
                                                    className={"col-5 table-td"}>{intl.formatMessage({id: 'general.title'})}</div>
                                                <div
                                                    className={"col-4 table-td"}>{intl.formatMessage({id: 'general.email'})}</div>
                                                <div className={"col-3 table-td"}></div>
                                            </div>
                                        </div>


                                        <div className={"table-body"}>
                                            {
                                                users.map((item, i) => (
                                                    <div className={"table-row"} key={i}>
                                                        <div className={"row"}>
                                                            <div
                                                                className={"col-5 table-td"}>{item.first_name} {item.last_name}</div>
                                                            <div
                                                                className={"col-4 table-td"}>{item.email}</div>
                                                            <div className={"col-3 table-td"}>
                                                                <Button
                                                                    className={"btn-light btn-edit ms-auto"}
                                                                    icon={"fa-solid fa-pen"}
                                                                    tooltip={intl.formatMessage({id: 'general.edit'})}
                                                                    onClick={() => navigate('/users/' + item.id)}
                                                                />
                                                                {!item.current && <Button
                                                                    className={"btn-light btn-delete"}
                                                                    icon={"fa-solid fa-trash-alt"}
                                                                    tooltip={intl.formatMessage({id: 'general.delete'})}
                                                                    onClick={() => {
                                                                        setModalIsOpen(true)
                                                                        setItemToDelete(item.id)

                                                                    }}
                                                                />}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </div>
                        }

                        <div className={"footer"}>

                        </div>
                    </div>
                </div>
            </div>

            <ModalConfirmDelete
                loading={loadingDelete}
                modalIsOpen={modalIsOpen}
                title={intl.formatMessage({id: 'settings.users.delete_user'})}
                onRequestClose={() => setModalIsOpen(false)}
                onConfirm={() => deleteUser()}
            />
        </div>
    );
}

