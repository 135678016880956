import React, {useEffect, useState} from "react";

function CustomInput({className, onChange, initValue, type,style = {},key}) {

    const [value, setValue] = useState(initValue)

    useEffect(() => {
        onChange(value)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    return (
        <input
            className={className}
            type={type}
            style={style}
            value={value}
            key={key}
            onChange={(e) => setValue(e.target.value)}
        />
    );
}

export default CustomInput;
