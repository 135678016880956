import Button from "../../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import {useEffect, useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import React from "react";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import LoadingCompHover from "../../../../components/elements/LoadingCompHover/LoadingCompHover";
import UploadImageComp from "../../../../components/Upload/UploadImageComp";
import SignaturePad from "react-signature-canvas";
import './Company.scss';
import Selector from "../../../../components/elements/Selector/Selector";
import {useOutletContext} from "react-router-dom";
import {Tooltip} from "react-tooltip";

export default function Company() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [title, setTitle] = useState('')
    const [fiscalCode, setFiscalCode] = useState('')
    const [change, setChange] = useState(0)
    const [address, setAddress] = useState('')
    const [county, setCounty] = useState(0)
    const [vatPayer, setVatPayer] = useState(false)
    const [counties, setCounties] = useState([])
    const [city, setCity] = useState('')
    const [regComNumber, setRegComNumber] = useState('')
    const [legalRepresentativeName, setLegalRepresentativeName] = useState('')
    const [legalRepresentativeFunction, setLegalRepresentativeFunction] = useState('')
    const [invoiceType, setInvoiceType] = useState('')
    const [billingEmail, setBillingEmail] = useState('')
    const [billingToken, setBillingToken] = useState('')
    const [bankAccount, setBankAccount] = useState('')
    const [bank, setBank] = useState('')

    const [useImage, setUseImage] = useState(false)
    const [newImage, setNewImage] = useState(null)
    const [imageUrl, setImageUrl] = useState('')
    const [sigPad, setSigPad] = useState({})
    const [update, setUpdate] = useState(0)

    const [loading, setLoading] = useState(true)
    const [loadingCui, setLoadingCui] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)
    const {context} = useOutletContext()




    useEffect(() => {
        getCounties()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    useEffect(() => {
        getCompanyInfo()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [change])

    const getCounties = async () => {
        axios.get(process.env.REACT_APP_API + '/counties', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                for (let i = 0; i < response.data.counties.length; i++) {
                    temp.push({
                        value: response.data.counties[i].id,
                        label: response.data.counties[i].title
                    })
                }

                setCounties(temp)
                getData(temp)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getData = async (temp = counties) => {
        axios.get(process.env.REACT_APP_API + '/company', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setTitle(response.data.title)
                setFiscalCode(response.data.fiscal_code ?? '')
                setAddress(response.data.address ?? '')

                let countyFind = temp.find(item => item.label === response.data.county)
                if (countyFind)
                    setCounty(countyFind.value)
                setCity(response.data.city ?? '')
                setRegComNumber(response.data.reg_com_number ?? '')//
                setLegalRepresentativeName(response.data.legal_representative_name ?? '')
                setLegalRepresentativeFunction(response.data.legal_representative_function ?? '')
                setLoading(false)
                setInvoiceType(response.data.billing_type)
                setVatPayer(response.data.vat_payer)
                setBankAccount(response.data.bank_account ?? '')
                setBank(response.data.bank ?? '')
                if (response.data.billing_type === 'oblio') {
                    setBillingEmail(response.data.billing_email_oblio)
                    setBillingToken(response.data.billing_token_oblio)
                }
                if (response.data.billing_type === 'smartbill') {
                    setBillingEmail(response.data.billing_email_smartbill)
                    setBillingToken(response.data.billing_token_smartbill)
                }

                if (response.data.signature) {
                    setImageUrl(process.env.REACT_APP_API.replace('/api','') + response.data.signature.url)
                    setUseImage(true)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const getCompanyInfo = async () => {

        if ((fiscalCode.length === 8 && !fiscalCode.includes('RO')) || (fiscalCode.length === 10 && fiscalCode.includes('RO'))) {

            setLoadingCui(true)
            axios.post(process.env.REACT_APP_API + '/company-info',
                {fiscal_code: fiscalCode}, {
                    headers: {
                        'Authorization': 'Bearer ' + jwt
                    }
                })
                .then(async (response) => {
                    if (response.data.data) {
                        setVatPayer(response.data.data.tva === "1" ? true : false)
                        setRegComNumber(response.data.data.cod_inmatriculare)
                        let countyFind = counties.find(item => item.label.toLowerCase() === response.data.data.adresa_judet.toLowerCase())
                        if (countyFind)
                            setCounty(countyFind.value)
                        setCity(response.data.data.adresa_localitate)
                        setAddress(response.data.data.adresa)
                        setTitle(response.data.data.nume)

                    }
                    setLoadingCui(false)
                })
                .catch(err => {
                    errorParser(err, intl)
                    setLoadingCui(false)
                })
        }
    }
    const dataURLtoFile = (dataurl, filename) => {
        var arr = dataurl.split(","),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }


        return new File([u8arr], filename, {type: mime});
    }
    const save = async (e) => {

        e.preventDefault();
        if (county === 0 || city === '') {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }


        setSaveLoading(true)
        let formData = new FormData()

        formData.append('billing_email', billingEmail)
        formData.append('billing_token', billingToken)
        formData.append('billing_type', invoiceType)


        if (newImage || sigPad) {
            formData.append('signature', useImage ? newImage : dataURLtoFile(sigPad.getTrimmedCanvas().toDataURL('image/png'), 'sign.png'))
        }
        let countyFind = counties.find(item => item.value === county)
        formData.append('data', JSON.stringify({
            title: title,
            fiscal_code: fiscalCode,
            county: countyFind.label,
            city: city,
            vat_payer: vatPayer,
            address: address,
            bank_account: bankAccount,
            bank: bank,
            reg_com_number: regComNumber,
            legal_representative_name: legalRepresentativeName,
            legal_representative_function: legalRepresentativeFunction,
        }))

        await axios.put(process.env.REACT_APP_API + '/company', formData, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.status === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                } else {
                    getData()
                    toast.error(response.data.error)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    const clear = () => {
        sigPad.clear()
    }

    function capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    if (loading) {
        return <LoadingComp/>
    }
    return (
        <div className="card" style={{position: "relative"}}>

            {
                loadingCui &&
                <LoadingCompHover/>
            }

            <form onSubmit={(e) => save(e)}>
                <div className={"header align-items-center"}>
                    <p> {intl.formatMessage({id: 'settings.menu.edit_company'})}</p>
                </div>
                <div className={"content"}>
                    <div className={"row"}>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.fiscal_code'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true} maxLength={8}
                                       value={fiscalCode} onChange={(e) => {
                                    setChange(change + 1)
                                    context.setContentUpdated(true)
                                    if (e.target.value.length > 8)
                                        setFiscalCode(e.target.value.replace('RO', '').replace('ro', '').replace('Ro', ''))
                                    else
                                        setFiscalCode(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <label className={"form-label d-flex mt-4"}>
                                    <input type={"checkbox"}
                                           className={"mb-0 mt-0"}
                                           checked={vatPayer}
                                           key={vatPayer}
                                           onChange={(e) => {
                                               setVatPayer(!vatPayer)
                                               setUpdate(update + 1)
                                           }}/>
                                    <p className={"ms-1 m-0"}>{intl.formatMessage({id: 'settings.company.vat_payer'})}{vatPayer}</p>
                                </label>

                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.title'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={title} onChange={(e) => {

                                    context.setContentUpdated(true)
                                    setTitle(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.reg_com_number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} id={'reg-com-number-1'}
                                       required={true}
                                       value={regComNumber} onChange={(e) => {

                                    context.setContentUpdated(true)
                                    setRegComNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.county'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required
                                    options={counties}
                                    value={county}
                                    key={county}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setCounty(option.value)
                                        setCity('')
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        {
                            county === 1 ?
                                <div className={"col-md-6 col-sm-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                                            className={"form-required"}>*</span></div>
                                        <Selector
                                            required
                                            options={[{
                                                value: 'Sector 1',
                                                label: 'Sector 1',
                                            }, {
                                                value: 'Sector 2',
                                                label: 'Sector 2',
                                            }, {
                                                value: 'Sector 3',
                                                label: 'Sector 3',
                                            }, {
                                                value: 'Sector 4',
                                                label: 'Sector 4',
                                            }, {
                                                value: 'Sector 5',
                                                label: 'Sector 5',
                                            }, {
                                                value: 'Sector 6',
                                                label: 'Sector 6',
                                            },]}
                                            value={city}
                                            onChange={(option) => {
                                                context.setContentUpdated(true)
                                                setCity(option.value)
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                </div>
                                :
                                <div className={"col-md-6 col-sm-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.city'})}<span
                                            className={"form-required"}>*</span></div>
                                        <input type={"text"} className={"form-input"} required={true}
                                               value={city}
                                               onChange={(e) => {
                                                   context.setContentUpdated(true)
                                                   setCity(e.target.value)
                                               }}
                                        />
                                    </div>
                                </div>}
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.address'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={address} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setAddress(e.target.value)
                                }}/>
                            </div>
                        </div>

                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_name'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={legalRepresentativeName}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setLegalRepresentativeName(e.target.value)
                                       }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.legal_representative_function'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"text"} className={"form-input"} required={true}
                                       value={legalRepresentativeFunction}
                                       onChange={(e) => {
                                           context.setContentUpdated(true)
                                           setLegalRepresentativeFunction(e.target.value)
                                       }}/>
                            </div>
                        </div>

                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank_account'})}</div>
                                <input type={"text"} className={"form-input"}

                                       value={bankAccount} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setBankAccount(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-6 col-sm-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.bank'})}</div>
                                <input type={"text"} className={"form-input"}

                                       value={bank} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setBank(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-md-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'settings.company.billing_type'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    required
                                    options={[{
                                        value: 'oblio',
                                        label: 'Oblio'
                                    }, {
                                        value: 'smartbill',
                                        label: 'Smartbill'
                                    },]}
                                    value={invoiceType}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setInvoiceType(option.value)
                                        setBillingEmail('')
                                        setBillingToken('')
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        {
                           invoiceType !== null &&
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage({id: 'general.email'})} {capitalizeFirstLetter(invoiceType)}<span
                                        className={"form-required"}>*</span></div>

                                    <input type={"email"} className={"form-input"}
                                           value={billingEmail} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setBillingEmail(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        }
                        {
                            invoiceType !== null &&
                            <div className={"col-12"}>
                                <div className={"form-control"}>
                                    <div
                                        className={"form-label"}>{intl.formatMessage(
                                        {id: invoiceType === 'smartbill' ? 'settings.company.billing_token' : 'settings.company.client_secret'})} {capitalizeFirstLetter(invoiceType)}<span
                                        className={"form-required"}>*</span><i data-tooltip-id={"info"}
                                                                               className={"fa-solid fa-circle-info ms-3"}/>
                                    </div>
                                    <Tooltip id={"info"}>
                                        {invoiceType === 'smartbill' ?
                                            intl.formatMessage({id: 'settings.company.info.smartbill'})
                                            :

                                            intl.formatMessage({id: 'settings.company.info.oblio'})}
                                    </Tooltip>
                                    <input type={"text"} className={"form-input"}
                                           value={billingToken} onChange={(e) => {
                                        context.setContentUpdated(true)
                                        setBillingToken(e.target.value)
                                    }}/>
                                </div>
                            </div>
                        }
                        <div className={"col-12"}>

                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'settings.company.add_the_signature'})}</div>
                            <div className={"tab-switch with-content"}>
                                <div className={"tab " + (!useImage ? "active" : "")} onClick={() => {
                                    context.setContentUpdated(true)
                                    setUseImage(false)
                                }}>
                                    {intl.formatMessage({id: 'settings.company.sign_here'})}
                                </div>
                                <div className={"tab " + (useImage ? "active" : "")} onClick={() => {
                                    context.setContentUpdated(true)
                                    setUseImage(true)
                                }}>
                                    {intl.formatMessage({id: 'settings.locations.upload_image'})}
                                </div>
                            </div>
                            <div className={"tab-content p-2"}>
                                {
                                    useImage ?
                                        <UploadImageComp
                                            image={newImage}
                                            setImage={setNewImage}
                                            imageUrl={imageUrl}
                                            setImageUrl={setImageUrl}
                                            className={"p-3"}
                                        />
                                        :
                                        <div>
                                            <div className={"signature-container"}
                                                 style={{padding: '40px 10px'}}
                                                 onMouseUp={() => {
                                                     context.setContentUpdated(true)
                                                     setUpdate(update + 1)
                                                 }
                                                 }
                                            >
                                                <SignaturePad ref={(ref) => {
                                                    setSigPad(ref)
                                                }} canvasProps={{className: 'sigCanvas'}}/>
                                                <div key={update}
                                                     className={((sigPad && Object.keys(sigPad).length === 0)
                                                     || (sigPad && sigPad.isEmpty()) ? 'signature-overlay' : 'd-none')}>
                                                    <i className="fa-solid fa-pencil icon"></i>
                                                    <div
                                                        className={"title"}>{intl.formatMessage({id: "general.add.signature.title"})}</div>
                                                </div>
                                            </div>


                                            <Button key={update}
                                                    className={"btn-gray mt-2 ms-auto " + ((sigPad && Object.keys(sigPad).length === 0)
                                                    || (sigPad && sigPad.isEmpty()) ? 'disabled' : '')}
                                                    style={{width: '100%'}}
                                                    icon={"fa-solid fa-trash-alt"}
                                                    value={intl.formatMessage({id: 'general.buttons.delete'})}
                                                    type={'button'}
                                                    disabled={(sigPad && Object.keys(sigPad).length === 0)
                                                        || (sigPad && sigPad.isEmpty())}
                                                    onClick={() => clear()}
                                            />
                                        </div>
                                }
                            </div>

                        </div>
                    </div>
                </div>

                <div className={"footer d-flex"}>
                    <Button
                        className={"btn-primary mt-2 ms-auto"}
                        icon={"fa-regular fa-floppy-disk"}
                        value={intl.formatMessage({id: 'general.buttons.save'})}
                        type={'submit'}
                        disabled={saveLoading}
                        loading={saveLoading}
                    />
                </div>
            </form>
        </div>


    );
}

