import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import {useParams} from "react-router-dom";
import LoadingComp from "../../../../components/elements/LoadingComp/LoadingComp";
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";

export default function EmailMailjet() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    let params = useParams();

    const [email, setEmail] = useState()
    const [title, setTitle] = useState()
    const [saveLoading, setSaveLoading] = useState(false)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/companies/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if(response.data){
                    setEmail(response.data.email_mailjet)
                    setTitle(response.data.title_mailjet)
                }
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault()
        setSaveLoading(true)
        axios.post(process.env.REACT_APP_API + '/companies/' + params.id, {email_mailjet: email, title_mailjet: title},{
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))

            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>
                <div className={"row"}>
                    <div className={"col-lg-4 col-sm-12 float-right"}>
                        <p className={"container-title"}>{intl.formatMessage({id: 'settings.menu.email_mailjet'})}</p>
                    </div>
                </div>
            </div>

            <form style={{height: '-webkit-fill-available'}} onSubmit={(e) => {
                save(e)
            }}>
            <div className={"content"}>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.title'})}</div>
                            <input type={"text"}
                                   className={"form-input"}
                                   onFocus={(event) => event.target.select()}
                                   value={title} onChange={(e) => {

                                setTitle(e.target.value)
                            }}/>
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.email'})}</div>
                            <input type={"email"}
                                   className={"form-input"}
                                   onFocus={(event) => event.target.select()}
                                   value={email} onChange={(e) => {

                                setEmail(e.target.value)
                            }}/>
                        </div>
                    </div>

                </div>
            </div>

            <div className={"footer d-flex"}>
                <Button
                    className={"btn-primary mt-4 ms-auto"}
                    icon={"fa-regular fa-floppy-disk"}
                    value={intl.formatMessage({id: 'general.buttons.save'})}
                    type={'submit'}
                    loading={saveLoading}
                    disabled={saveLoading}
                />
            </div>
            </form>
        </div>

    );
}

