import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Requests.scss'
import Button from "../../../../../components/elements/Button/Button";
import {requestStatuses} from "../../../../../utils/constants";
import {toast} from "react-toastify";

export default function Contract() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [contractId, setContractId] = useState(null)
    const [status, setStatus] = useState('')
    const [saveLoading, setSaveLoading] = useState(false)


    const [loading, setLoading] = useState(true)
    useEffect(() => {

        getContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContract = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setContractId(response.data.contract ? response.data.contract.id : null)
                setStatus(response.data.status)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const complete = async () => {
        setSaveLoading(true)

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.get(process.env.REACT_APP_API + '/project-requests/complete/' + params.id,  {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate('/projects')
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-md-6 col-sm-12 "}>
                        <p>{intl.formatMessage({id: 'requests.contract'})}
                        </p>
                    </div>
                    <div className={"col-md-6 col-sm-12 "}>
                        {status === 'contract_signed' &&<Button
                                className={"btn-primary d-flex align-items-center ms-auto"}
                                icon={"fa-solid fa-check"}
                                value={intl.formatMessage({id: 'requests.complete'})}
                                onClick={() => complete()}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />}
                    </div>
                </div>
            </div>

            <div className={"content"}>
                <div className={"row mb-4"}>


                    {contractId && requestStatuses.indexOf(status) <= 8 &&
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.status.' + status})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-pen"}
                                value={intl.formatMessage({id: 'general.buttons.edit'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/contract/generate')}
                            />
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'requests.edit_file'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/contract/file')}
                            />
                        </div>
                    }
                    {contractId && requestStatuses.indexOf(status) > 8 &&
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.status.' + status})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-eye"}
                                value={intl.formatMessage({id: 'general.buttons.view'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/contract/view')}
                            />
                        </div>
                    }
                    {!contractId ?
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.contract_not_generate'})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'general.buttons.generate'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/contract/generate')}
                            />
                        </div>
                        :
                        <div className={"col-12"}>
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'general.buttons.annexes'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/contract/'+contractId+'/annexes')}
                            />
                        </div>
                    }



                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>

                </div>
            </div>

        </div>
    );
}