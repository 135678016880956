import Button from "../../../components/elements/Button/Button";
import {useIntl} from "react-intl";
import React, {useState} from "react";
import axios from "axios";
import {toast} from "react-toastify";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useOutletContext} from "react-router-dom";

export default function ReportBugs() {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const [message, setMessage] = useState('')

    const {context} = useOutletContext()
    const [saveLoading, setSaveLoading] = useState(false)

    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)

        await axios.put(process.env.REACT_APP_API + '/users/reportBugs', {message}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })

        setSaveLoading(false)
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.bugs'})}</h1>
                </div>
                <div className={"col-12"}>
                    <div className="card">

                        <form onSubmit={(e) => save(e)}>
                            <div className={"content"}>
                                <div className={"row"}>
                                    <div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'settings.notifications.message'})}<span
                                                className={"form-required"}>*</span></div>
                                            <textarea className={"form-input"} required rows={5} style={{height: 'unset'}}
                                                   value={message} onChange={(e) => {
                                                setMessage(e.target.value)
                                                context.setContentUpdated(true)
                                            }}/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className={"footer"}>
                                <div className={"row"}>
                                    <div className={"col-12 d-flex"}>
                                        <Button
                                            className={"btn-primary mt-2 ms-auto"}
                                            icon={"fa-solid fa-paper-plane"}
                                            value={intl.formatMessage({id: 'general.buttons.send'})}
                                            type={"submit"}
                                            loading={saveLoading}
                                            disabled={saveLoading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

