import {useIntl} from "react-intl";
import React, {useEffect, useRef, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Requests.scss'
import Button from "../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import {Editor} from "@tinymce/tinymce-react";

export default function EditContractFile() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()
    const editorRef = useRef(null);
    const editorRef2 = useRef(null);

    const [content, setContent] = useState(null)
    const [status, setStatus] = useState('')
    const [header, setHeader] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    useEffect(() => {
        getContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getContract = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.contract && response.data.contract.id) {
                    setContent(response.data.contract.content)
                    setHeader(response.data.contract.header)
                }
                setStatus(response.data.status)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (sent_to_client = false) => {
        setSaveLoading(true)
        let payload = {
            content,
            header,
            sent_to_client,
        }

        await axios.post(process.env.REACT_APP_API + '/contracts/edit-content/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    await getContract()
                }
                if (context.setUpdateStatus) {
                    context.setUpdateStatus(context.updateStatus + 1)
                }
                context.setContentUpdated(false)
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12"}>
                        <p>{intl.formatMessage({id: 'requests.contract'})}</p>
                    </div>
                </div>
            </div>

            <div className={"content"}>
                <div className={"row mb-4"}>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.templates.header'})}<span
                                className={"form-required"}>*</span></div>
                            <Editor
                                onInit={(evt, editor) => editorRef2.current = editor}
                                apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                initialValue={header}
                                onEditorChange={(e) => {
                                    context.setContentUpdated(true)
                                    setHeader(e)
                                }}
                                init={{
                                    table_column_resizing: 'preservetable',
                                    selector: 'textarea',
                                    menubar: 'edit view insert format',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                        'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code', 'fullscreen', 'pagebreak',
                                    ],
                                    toolbar:
                                        'fullscreen | styles | pagebreak | ' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent |' +
                                        'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                        'help',
                                    export_image_proxy: 'proxy.php',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                            />
                        </div>
                    </div>
                    <div className={"col-12"}>
                        <div className={"form-control"}>
                            <div
                                className={"form-label"}>{intl.formatMessage({id: 'general.templates.content'})}<span
                                className={"form-required"}>*</span></div>
                            <Editor
                                onInit={(evt, editor) => editorRef.current = editor}
                                apiKey='1rb156cyg9vg206vb6cdpcvzrnh4iiwcewgc0owv61j1t8ev'
                                initialValue={content}
                                onEditorChange={(e) => {
                                    context.setContentUpdated(true)
                                    setContent(e)
                                }}
                                init={{
                                    table_column_resizing: 'preservetable',
                                    selector: 'textarea',
                                    menubar: 'edit view insert format',
                                    plugins: [
                                        'advlist', 'autolink', 'lists', 'link', 'charmap', 'preview', 'quickimage',
                                        'anchor', 'searchreplace', 'visualblocks', 'fullscreen',
                                        'insertdatetime', 'table', 'help', 'wordcount', 'searchreplace', 'code', 'fullscreen', 'pagebreak',
                                    ],
                                    toolbar:
                                        'fullscreen | styles | pagebreak | code |' +
                                        'bold italic forecolor | alignleft aligncenter ' +
                                        'alignright alignjustify | bullist numlist outdent indent |' +
                                        'table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | ' +
                                        'help',
                                    export_image_proxy: 'proxy.php',
                                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-4"}>
                        <Button
                            className={"btn-light mt-2"}
                            icon={"fa-solid fa-chevron-left"}
                            value={intl.formatMessage({id: 'general.buttons.back'})}
                            onClick={() => {
                                context.setLeavePage(true)
                                context.setNextLink('/requests/'+params.id+'/contract')
                            }}
                        />
                    </div>
                    <div className={"col-12 col-md-8 d-flex"}>
                        <Button
                            className={"btn-primary mt-2 ms-auto"}
                            icon={"fa-solid fa-file"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            type={'button'}
                            onClick={() => save(false)}
                            disabled={saveLoading}
                            loading={saveLoading}
                        />
                        {status !== 'contract_sent' && <Button
                            className={"btn-primary mt-2 ms-3"}
                            icon={"fa-solid fa-share"}
                            value={intl.formatMessage({id: 'general.buttons.save_and_send'})}
                            type={'button'}
                            onClick={() => save(true)}
                            disabled={saveLoading}
                            loading={saveLoading}
                        />}
                    </div>
                </div>
            </div>
        </div>
    );
}