import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Requests.scss'
import Button from "../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment/moment";
import Selector from "../../../../../components/elements/Selector/Selector";

export default function EditContract() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const {context} = useOutletContext()
    const navigate = useNavigate()

    const [contractId, setOfferId] = useState(null)
    const [status, setStatus] = useState('')
    const [templates, setTemplates] = useState([])
    const [templateSelected, setTemplateSelected] = useState(null)
    const [number, setNumber] = useState(null)
    const [seriesOptions, setSeriesOptions] = useState([])
    const [series, setSeries] = useState(null)


    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)
    useEffect(() => {

        getTemplates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOffer = async (temp) => {
        axios.get(process.env.REACT_APP_API + '/contracts/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.contract && response.data.contract.id) {
                    setOfferId(response.data.contract.id)
                    setTemplateSelected(response.data.contract.document_template.id)
                    setNumber(response.data.contract.number)
                    setSeries(temp.find(item => item.label === response.data.contract.series).value)
                }
                setStatus(response.data.status)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getTemplates = async () => {
        axios.post(process.env.REACT_APP_API + '/document-templates/find-for-request/' + params.id, {type: 'contract'}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data.templates) {
                    let temp = []
                    response.data.templates.forEach(item => {
                        temp.push({
                            value: item.id,
                            label: item.title,
                            content: item.content,
                            header: item.header
                        })
                    })
                    setTemplates(temp)
                }
                getSeries()
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getSeries = async () => {
        axios.get(process.env.REACT_APP_API + '/document-series', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data && response.data.length > 0) {
                    let temp = []
                    response.data.forEach(item => {
                        if (item['for'] === 'contract')
                            temp.push({
                                value: item.id,
                                label: item.series,
                                next_number: item.next_number,
                            })
                    })
                    setSeriesOptions(temp)
                    await getOffer(temp)
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    const save = async (sent_to_client = false) => {
        if ( series === null || number === null || templateSelected === null) {
            toast.error(intl.formatMessage({id: "errors.please_fill_all_fields"}))
            return
        }
        setSaveLoading(true)


        let payload = {
            date: moment().format('YYYY-MM-DD'),
            series: seriesOptions.find(item => item.value === series).label,
            number,
            template: templateSelected,
            sent_to_client,
            contract_id: contractId

        }

        await axios.put(process.env.REACT_APP_API + '/contracts/save-for-request/' + params.id, payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (contractId) {
                        await getOffer(seriesOptions)
                    }else {
                        navigate(-1)
                    }
                }

                context.setContentUpdated(false)
                if (context.setUpdateStatus){
                    context.setUpdateStatus(context.updateStatus + 1)
                }
                setSaveLoading(false)
            })
            .catch(err => {
                setSaveLoading(false)
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-12 "}>
                        <p>{intl.formatMessage({id: 'requests.contract'})}
                        </p>
                    </div>
                </div>
            </div>

                <div className={"content"}>
                    <div className={"row mb-4"}>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'contracts.series'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={seriesOptions}
                                    value={series}
                                    onChange={(option) => {
                                        context.setContentUpdated(true)
                                        setNumber(option.next_number)
                                        setSeries(option.value)
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.number'})}<span
                                    className={"form-required"}>*</span></div>
                                <input type={"number"} className={"form-input"} required
                                       value={number} onChange={(e) => {
                                    context.setContentUpdated(true)
                                    setNumber(e.target.value)
                                }}/>
                            </div>
                        </div>
                        <div className={"col-12"}>
                            <div className={"form-control"}>
                                <div
                                    className={"form-label"}>{intl.formatMessage({id: 'general.template'})}<span
                                    className={"form-required"}>*</span></div>
                                <Selector
                                    options={templates}
                                    value={templateSelected}
                                    onChange={(option) => {

                                        context.setContentUpdated(true)
                                        setTemplateSelected(parseInt(option.value))
                                    }}
                                    isSearchable={true}
                                    isOptionDisabled={(option) => option.disabled}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"footer"}>
                    <div className={"row"}>
                        <div className={"col-12 col-md-4"}>
                                <Button
                                    className={"btn-light mt-2"}
                                    icon={"fa-solid fa-chevron-left"}
                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                    onClick={() => {
                                        context.setLeavePage(true)
                                        context.setNextLink('/requests/'+params.id+'/contract')
                                    }}
                                />
                            </div>
                        <div className={"col-12 col-md-8 d-flex"}>
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'general.buttons.' + (contractId ? 'save' : 'generate')})}
                                type={'button'}
                                onClick={() => save(false)}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />
                            {status !== 'contract_sent' && <Button
                                className={"btn-primary mt-2 ms-3"}
                                icon={"fa-solid fa-share"}
                                value={intl.formatMessage({id: 'general.buttons.generate_and_send'})}
                                type={'button'}
                                onClick={() => save(true)}
                                disabled={saveLoading}
                                loading={saveLoading}
                            />}
                        </div>

                    </div>
                </div>
        </div>
    );
}