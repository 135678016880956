import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useNavigate, useParams} from "react-router-dom";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import '../../Requests.scss'
import Button from "../../../../../components/elements/Button/Button";
import {requestStatuses} from "../../../../../utils/constants";

export default function Offer() {

    const intl = useIntl();
    let params = useParams();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()

    const [offerId, setOfferId] = useState(null)
    const [status, setStatus] = useState('')


    const [loading, setLoading] = useState(true)
    useEffect(() => {

        getOffer()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOffer = async (temp) => {
        axios.get(process.env.REACT_APP_API + '/offers/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setOfferId(response.data.offer ? response.data.offer.id : null)
                setStatus(response.data.status)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card">
            <div className={"header"}>

                <div className={"row"}>
                    <div className={"col-md-4 col-sm-12 "}>
                        <p>{intl.formatMessage({id: 'requests.offer'})}
                        </p>
                    </div>
                </div>
            </div>

            <div className={"content"}>
                <div className={"row mb-4"}>

                    {!offerId &&
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.offer_not_generate'})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'general.buttons.generate'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/offer/generate')}
                            />
                        </div>
                    }
                    {offerId && requestStatuses.indexOf(status) <= 5 &&
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.status.' + status})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-pen"}
                                value={intl.formatMessage({id: 'general.buttons.edit'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/offer/generate')}
                            />
                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-file"}
                                value={intl.formatMessage({id: 'requests.edit_file'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/offer/file')}
                            />
                        </div>
                    }
                    {offerId && requestStatuses.indexOf(status) > 5 &&
                        <div className={"col-12"}>
                            <div className={"alert alert-info"}>{intl.formatMessage({id: 'requests.status.' + status})}</div>

                            <Button
                                className={"btn-primary mt-2 ms-auto"}
                                style={{width: '100%'}}
                                icon={"fa-solid fa-eye"}
                                value={intl.formatMessage({id: 'general.buttons.view'})}
                                type={'button'}
                                onClick={() => navigate('/requests/'+params.id+'/offer/view')}
                            />
                        </div>
                    }



                </div>
            </div>
            <div className={"footer"}>
                <div className={"row"}>

                </div>
            </div>

        </div>
    );
}