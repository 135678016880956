import React, {useEffect, useState} from "react";
import LoadingComp from "../../../../../components/elements/LoadingComp/LoadingComp";
import axios from "axios";
import errorParser from "../../../../../components/ErrorParser/ErrorParser";
import {useIntl} from "react-intl";
import {useNavigate, useOutletContext, useParams} from "react-router-dom";
import Selector from "../../../../../components/elements/Selector/Selector";
import Button from "../../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import moment from "moment/moment";

export default function AddAnnex() {
    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')
    const navigate = useNavigate()
    let params = useParams();

    const [isFirstAnnex, setIsFirstAnnex] = useState(false)
    const [loading, setLoading] = useState(true)
    const [template, setTemplate] = useState('')
    const [templates, setTemplates] = useState([])
    const [number, setNumber] = useState([])
    const [saveLoading, setSaveLoading] = useState(false)
    const [clientType, setClientType] = useState('')
    const {context} = useOutletContext()

    useEffect(() => {
        getContract()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getContract = async () => {
        axios.get(process.env.REACT_APP_API + '/contracts/find-info-for-request/' + params.id, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (response.data) {
                    setLoading(false)
                    getTemplates(response.data.client.type)
                }
            })
            .catch(err => {

                errorParser(err, intl)
            })
    }
    const getTemplates = async (type) => {
        setClientType(type)
        axios.get(process.env.REACT_APP_API + '/document-templates/list/0', {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                var items = [];
                response.data.templates.forEach((item) => {
                    if (type === item.client_type && item.type === 'annex') {
                        items.push({
                            value: item.id,
                            label: item.title,
                            content: item.content,
                            header: item.header
                        })
                    }

                })
                getLastNumber()
                setTemplates(items)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const getLastNumber = async () => {
        axios.get(process.env.REACT_APP_API + '/annexes/last-number/' + params.contract, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setNumber(parseInt(response.data.last_number) + 1)
                if (parseInt(response.data.last_number) === 0)
                    setIsFirstAnnex(true)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }
    const save = async (e) => {
        e.preventDefault();

        let payload = {
            template: template,
            number: number,
            contract: params.contract,
            client_type: clientType,
            date: moment().toISOString()
        }
        await axios.post(process.env.REACT_APP_API + '/annexes', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                context.setContentUpdated(false)
                navigate(-1)
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }

    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="card add-contract">
            <div className={"header"}><p>{intl.formatMessage({id: 'contracts.add_annex'})}</p></div>
                    <form onSubmit={(e) => save(e)}>
                        <div className={"content"}>
                            <div className={"row mb-4"}>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.number'})}</div>
                                        <input type={"number"}
                                               className={"form-input"}
                                               disabled={!isFirstAnnex}
                                               value={number}
                                               onChange={(e) => {
                                                   context.setContentUpdated(true)
                                                   setNumber(parseInt(e.target.value))
                                               }}
                                        />
                                    </div>
                                </div>
                                <div className={"col-12"}>
                                    <div className={"form-control"}>
                                        <div
                                            className={"form-label"}>{intl.formatMessage({id: 'general.template'})}</div>
                                        <Selector
                                            options={templates}
                                            value={template}
                                            onChange={(option) => {
                                                context.setContentUpdated(true)
                                                setTemplate(parseInt(option.value))
                                            }}
                                            isSearchable={true}
                                            isOptionDisabled={(option) => option.disabled}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"footer"}>
                            <div className={"row"}>
                                <div className={"col-12 d-flex"}>
                                    <Button
                                        className={"btn-primary mt-2 ms-auto"}
                                        icon={"fa-regular fa-floppy-disk"}
                                        value={intl.formatMessage({id: 'general.buttons.save'})}
                                        type={'submit'}
                                        disabled={saveLoading}
                                        loading={saveLoading}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>

        </div>
    );
}

