import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../../components/ErrorParser/ErrorParser";
import '../Requests.scss'
import Button from "../../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import LoadingCompHover from "../../../../components/elements/LoadingCompHover/LoadingCompHover";
import FileContainer from "./FileContainer";
import {documentsToUpload} from "../../../../utils/constants";

export default function UploadDocumentsForm({
                                                getDataLink,
                                                saveLink,
                                                context = null,
                                                updateRequest = null,
                                                setUpdateRequest = null
                                            }) {

    const intl = useIntl();
    const jwt = localStorage.getItem('jwt')

    const [documentsToUploadForCurrentType, setDocumentsToUploadForCurrentType] = useState(null)
    const [dataToUpload, setDataToUpload] = useState({gas_supplier_invoice_or_consumption_place_code: 'gas_supplier_invoice'})
    const [request, setRequest] = useState(null)

    const [loading, setLoading] = useState(true)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getData = async () => {
        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        axios.get(process.env.REACT_APP_API + getDataLink, {
            headers
        })
            .then(async (response) => {
                setRequest(response.data)
                setDocumentsToUploadForCurrentType(documentsToUpload[response.data.type])
                let temp = dataToUpload
                temp.consumption_place_code = response.data.consumption_place_code
                temp.gas_installation_file_number = response.data.gas_installation_file_number
                temp.seal_number = response.data.seal_number
                temp.counter_series = response.data.counter_series
                temp.counter_index = response.data.counter_index
                temp.has_attr = response.data.has_attr
                temp.attr_number = response.data.attr_number
                if (response.data.gas_supplier_invoice_or_consumption_place_code)
                    temp.gas_supplier_invoice_or_consumption_place_code = response.data.gas_supplier_invoice_or_consumption_place_code
                setDataToUpload(temp)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let formData = new FormData()

        Object.keys(dataToUpload).forEach(key => {
            if (dataToUpload[key]) {
                if (typeof dataToUpload[key] === "object") {
                    if (dataToUpload[key].length >= 1) {
                        for (let i = 0; i < dataToUpload[key].length; i++) {
                            formData.append(key, dataToUpload[key][i])
                        }
                    } else
                        formData.append(key, dataToUpload[key])
                }
            }
        })

        formData.append('data', JSON.stringify(dataToUpload))

        let headers = {}
        if (jwt)
            headers['Authorization'] = 'Bearer ' + jwt
        await axios.put(process.env.REACT_APP_API + saveLink, formData, {
            headers
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    if (context)
                        context.setContentUpdated(false)
                    if (setUpdateRequest) {
                        setUpdateRequest(updateRequest + 1)
                    }
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })
        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingCompHover/>
    }

    return (
        <form onSubmit={(e) => save(e)}>
            <div className={"content"}>
                <div className={"row"}>
                    {// eslint-disable-next-line
                        documentsToUploadForCurrentType.map((item, i) => {
                            // eslint-disable-next-line
                            switch (item.type) {
                                case 'file':
                                    if ((item.if_client && request.client.type === item.if_client) || !item.if_client)
                                        return (
                                            <FileContainer request={request} fileKey={item.key}
                                                           file={dataToUpload[item.key]}
                                                           multiple={item.multiple}
                                                           setFile={(file) => {
                                                               if (context)
                                                                   context.setContentUpdated(true)
                                                               let temp = {...dataToUpload}
                                                               temp[item.key] = file
                                                               setDataToUpload(temp)
                                                           }}/>)
                                    break;
                                case 'text':
                                    return (<div className={"col-12"}>
                                        <div className={"form-control"}>
                                            <div
                                                className={"form-label"}>{intl.formatMessage({id: 'requests.' + item.key})}
                                                {item.required && <span
                                                    className={"form-required"}>*</span>}</div>
                                            <input type={"text"} className={"form-input"}

                                                   value={dataToUpload[item.key]} required
                                                   onChange={(e) => {
                                                       let temp = {...dataToUpload}
                                                       if (context)
                                                           context.setContentUpdated(true)
                                                       temp[item.key] = e.target.value
                                                       setDataToUpload(temp)
                                                   }}
                                            />
                                        </div>
                                    </div>)
                                case 'boolean':
                                    return (<div className={"col-12"}>
                                        <div className={"mt-3 form-control"}>
                                            <label>
                                                <input type={"checkbox"}
                                                       checked={dataToUpload[item.key]}
                                                       required
                                                       onChange={() => {
                                                           if (context)
                                                               context.setContentUpdated(true)
                                                           let temp = {...dataToUpload}
                                                           temp[item.key] = !temp[item.key]
                                                           setDataToUpload(temp)
                                                       }}/>

                                                {intl.formatMessage({id: 'requests.' + item.key})}
                                            </label>
                                        </div>
                                    </div>)
                                case 'switch':
                                    return (<div className={"col-12 mt-3"}>
                                        <div className={"tab-switch with-content"}>

                                            {
                                                item.values.map((item2, j) => (
                                                    <div
                                                        className={"tab " + (dataToUpload[item.key] === item2.key ? "active" : "")}
                                                        onClick={() => {
                                                            if (context)
                                                                context.setContentUpdated(true)
                                                            let temp = {...dataToUpload}
                                                            temp[item.key] = item2.key
                                                            setDataToUpload(temp)
                                                        }}>
                                                        {intl.formatMessage({id: 'requests.' + item2.key})}
                                                    </div>
                                                ))
                                            }
                                        </div>
                                        <div className={"tab-content p-2"}>

                                            {// eslint-disable-next-line
                                                item.values.map((item2, j) => {
                                                    if (dataToUpload[item.key] === item2.key)
                                                        // eslint-disable-next-line
                                                        switch (item2.type) {
                                                            case 'file':
                                                                return (<FileContainer
                                                                    request={request}
                                                                    fileKey={item2.key}
                                                                    file={dataToUpload[item2.key]}
                                                                    multiple={item2.multiple}
                                                                    setFile={(file) => {
                                                                        if (context)
                                                                            context.setContentUpdated(true)
                                                                        let temp = {...dataToUpload}
                                                                        temp[item2.key] = file
                                                                        setDataToUpload(temp)
                                                                    }}/>)
                                                            case 'text':
                                                                return (
                                                                    <div className={"col-12"}>
                                                                        <div
                                                                            className={"form-control"}>
                                                                            <div
                                                                                className={"form-label"}>{intl.formatMessage({id: 'requests.' + item2.key})}
                                                                                {item2.required &&
                                                                                    <span
                                                                                        className={"form-required"}>*</span>}</div>
                                                                            <input type={"text"}
                                                                                   className={"form-input"}

                                                                                   value={dataToUpload[item2.key]}
                                                                                   required
                                                                                   onChange={(e) => {
                                                                                       if (context)
                                                                                           context.setContentUpdated(true)
                                                                                       let temp = {...dataToUpload}
                                                                                       temp[item2.key] = e.target.value
                                                                                       setDataToUpload(temp)
                                                                                   }}
                                                                            />
                                                                        </div>
                                                                    </div>)
                                                        }
                                                })}
                                        </div>
                                    </div>)
                            }
                        })
                    }
                </div>
            </div>

            <div className={"footer"}>
                <div className={"row"}>
                    <div className={"col-12 col-md-6"}>
                    </div>
                    <div className={"col-12 col-md-6 d-flex"}>
                        <Button
                            className={"btn-primary mt-2 ms-auto"}
                            icon={"fa-regular fa-floppy-disk"}
                            value={intl.formatMessage({id: 'general.buttons.save'})}
                            disabled={saveLoading}
                            loading={saveLoading}
                            type={"submit"}
                        />
                    </div>
                </div>
            </div>
        </form>
    );
}

