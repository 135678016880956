import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import {useNavigate} from "react-router-dom";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import {requestTypes} from "../../../utils/constants";
import './Requests.scss'
import Button from "../../../components/elements/Button/Button";
import {toast} from "react-toastify";
import Selector from "../../../components/elements/Selector/Selector";
import ModalComp from "../../../components/ModalComp/ModalComp";
import AddClientForm from "../Clients/AddClientForm";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import AddPlaceForm from "../Clients/EditClient/Places/AddPlaceForm";

export default function AddRequest() {

    const intl = useIntl();
    const navigate = useNavigate()
    const jwt = localStorage.getItem('jwt')


    const [clients, setClients] = useState([])
    const [step, setStep] = useState(1)
    const [type, setType] = useState(null)

    const [client, setClient] = useState(null)
    const [placeOfConsumptionOptions, setPlaceOfConsumptionOptions] = useState([])
    const [placeOfConsumption, setPlaceOfConsumption] = useState(null)
    const [date, setDate] = useState('')

    const [loading, setLoading] = useState(true)
    const [showModalAddClient, setShowModalAddClient] = useState(false)
    const [showModalAddPlace, setShowModalAddPlace] = useState(false)
    const [saveLoading, setSaveLoading] = useState(false)

    useEffect(() => {
        getClients()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const getClients = async () => {
        axios.post(process.env.REACT_APP_API + '/clients/list/0', {}, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                let temp = []
                response.data.clients.forEach(item => {
                    temp.push({
                        value: item.id,
                        label: (item.type === 'individual' ? (item.first_name + ' ' + item.last_name) : item.company_title) + (item.email ? ('(' + item.email + ')') : ''),
                        place_of_consumptions: item.place_of_consumptions
                    })
                })
                setClients(temp)
                if (client !== null){
                    let temp2 = []
                    temp.find(item => item.value === client).place_of_consumptions.forEach(item => {
                        temp2.push({
                            value: item.id,
                            label: item.address +','+ item.city +','+ item.county
                        })
                    })
                    setPlaceOfConsumptionOptions(temp2)
                }

                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }


    const save = async (e) => {
        e.preventDefault();
        setSaveLoading(true)
        let payload = {
            type,
            client,
            place_of_consumption: placeOfConsumption,
            project_date: moment(date).toISOString(),
        }

        await axios.post(process.env.REACT_APP_API + '/project-requests', payload, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                if (parseInt(response.data.status) === 1) {
                    toast.success(intl.formatMessage({id: 'errors.success.data_has_been_saved'}))
                    navigate('/requests/' + response.data.request.id + '/edit')
                }
            })
            .catch(err => {
                errorParser(err, intl)
            })


        setSaveLoading(false)
    }
    if (loading) {
        return <LoadingComp/>
    }

    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'requests.title'})}</h1>
                </div>

                {
                    step === 1 ?
                        requestTypes.map((item, i) => (
                            <div className={"col-md-6 col-12 pb-4"} key={i} onClick={() => {
                                setType(item)
                                setStep(2)
                            }}>
                                <div className="card card-request-type" style={{
                                    minHeight: '100px', cursor: "pointer", justifyContent: "center",
                                    display: "flex", alignItems: "center"
                                }}>
                                    {intl.formatMessage({id: 'requests.type.' + item})}
                                </div>
                            </div>
                        ))
                        :
                        <div className={"col-md-12 pb-4"}>
                            <div className="card">
                                <div className={"header"}>

                                    <div className={"row"}>
                                        <div className={"col-md-3 col-sm-12"}>
                                            <p>{intl.formatMessage({id: 'requests.add_request'})}</p>
                                        </div>
                                        <div className={"col-md-9 col-sm-12 float-right"}>
                                          <div style={{display: "flex"}}>
                                              <Button
                                                  className={"btn-primary d-flex align-items-center ms-auto"}
                                                  value={intl.formatMessage({id: 'clients.add_new_client'})}
                                                  onClick={() => setShowModalAddClient(true)}
                                              />
                                              {client !== null &&<Button
                                                  className={"btn-primary d-flex align-items-center ms-2"}
                                                  value={intl.formatMessage({id: 'clients.add_new_place_of_consumption'})}
                                                  onClick={() => setShowModalAddPlace(true)}
                                              />}
                                          </div>
                                        </div>
                                    </div>
                                </div>

                                <form onSubmit={(e) => save(e)}>
                                    <div className={"content"}>
                                        <div className={"row"}>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'general.client'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <Selector
                                                        required
                                                        options={clients}
                                                        value={client}
                                                        onChange={(option) => {
                                                            setClient(option.value)
                                                            let temp = []
                                                            option.place_of_consumptions.forEach(item => {
                                                                temp.push({
                                                                    value: item.id,
                                                                    label: item.address +','+ item.city +','+ item.county
                                                                })
                                                            })
                                                            setPlaceOfConsumption(null)
                                                            setPlaceOfConsumptionOptions(temp)
                                                        }}
                                                        isSearchable={true}
                                                        isOptionDisabled={(option) => option.disabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'general.place_of_consumption'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <Selector
                                                        required
                                                        key={placeOfConsumptionOptions.length}
                                                        options={placeOfConsumptionOptions}
                                                        value={placeOfConsumption}
                                                        onChange={(option) => {
                                                            setPlaceOfConsumption(option.value)
                                                        }}
                                                        isSearchable={true}
                                                        isOptionDisabled={(option) => option.disabled}
                                                    />
                                                </div>
                                            </div>
                                            <div className={"col-12"}>
                                                <div className={"form-control"}>
                                                    <div
                                                        className={"form-label"}>{intl.formatMessage({id: 'general.project_date'})}<span
                                                        className={"form-required"}>*</span></div>
                                                    <DatePicker
                                                        locale={moment.locale()}
                                                        placeholderText={intl.formatMessage({id: 'general.project_date'})}
                                                        className={"form-input"}
                                                        selected={date}
                                                        onChange={(date) => setDate(date)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className={"footer"}>
                                        <div className={"row"}>
                                            <div className={"col-12 col-md-6"}>
                                                <Button
                                                    className={"btn-light mt-2"}
                                                    icon={"fa-solid fa-chevron-left"}
                                                    value={intl.formatMessage({id: 'general.buttons.back'})}
                                                    onClick={() => {
                                                        setStep(1)

                                                    }}
                                                />
                                            </div>
                                            <div className={"col-12 col-md-6 d-flex"}>
                                                <Button
                                                    className={"btn-primary mt-2 ms-auto"}
                                                    icon={"fa-regular fa-floppy-disk"}
                                                    value={intl.formatMessage({id: 'general.buttons.save'})}
                                                    disabled={saveLoading}
                                                    loading={saveLoading}
                                                    type={"submit"}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>

                            </div>
                        </div>
                }
                <ModalComp
                    title={intl.formatMessage({id: 'clients.add_new_client'})}
                    isOpen={showModalAddClient}
                    onRequestClose={() => setShowModalAddClient(false)}
                    className={"add-client-modal"}
                >

                    <AddClientForm
                        id={0}
                        isOnSave={true}
                        onSave={(temp) => {
                            setClient(temp.id)
                            setPlaceOfConsumption(null)
                            getClients()
                            setShowModalAddClient(false)
                        }}
                    />
                </ModalComp>
                <ModalComp
                    title={intl.formatMessage({id: 'clients.add_new_place_of_consumption'})}
                    isOpen={showModalAddPlace}
                    onRequestClose={() => setShowModalAddPlace(false)}
                    className={"add-client-modal"}
                >

                    <AddPlaceForm
                        clientId={client}
                        id={0}
                        isOnSave={true}
                        onSave={(temp) => {
                            setPlaceOfConsumption(temp.id)
                            setShowModalAddPlace(false)
                            getClients()
                        }}
                    />
                </ModalComp>
            </div>
        </div>
    );
}

