import {useIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import axios from "axios";
import OffersTable from "./OffersTable";
import LoadingComp from "../../../components/elements/LoadingComp/LoadingComp";
import errorParser from "../../../components/ErrorParser/ErrorParser";
import Paginate from "../../../components/elements/Paginate/Paginate";

export default function OffersList() {

    const intl = useIntl()
    const jwt = localStorage.getItem('jwt')


    const [offers, setOffers] = useState([])
    const [page, setPage] = useState(1)
    const [totalOffers, setTotalOffers] = useState(0)

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getData()
        }, 600)

        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])
    const getData = async () => {
        axios.get(process.env.REACT_APP_API + '/offers/list/' + page, {
            headers: {
                'Authorization': 'Bearer ' + jwt
            }
        })
            .then(async (response) => {
                setOffers(response.data.offers ?? [])
                setTotalOffers(response.data.total ?? 0)
                setLoading(false)
            })
            .catch(err => {
                errorParser(err, intl)
            })
    }

    if (loading) {
        return <LoadingComp/>
    }


    return (
        <div className="container">

            <div className={"row"}>
                <div className={"col-12"}>
                    <h1 className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.offers'})}</h1>
                </div>
                <div className={"col-lg-12"}>

                    <div className="card">

                        <div className={"header align-items-center"}>
                            <p className={"container-title"}>{intl.formatMessage({id: 'general.sidebar.offers'})}</p>

                        </div>

                        <OffersTable
                            offers={offers}
                        />

                        <div className={"footer d-flex justify-content-center"}>
                            <Paginate
                                page={page}
                                setPage={setPage}
                                totalItems={totalOffers}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

